import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, NgForm, NgModel } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { GLOBAL } from '../../../services/global';
import { UserService } from '../../../services/user.service';
import { ProductorService } from '../../../services/productor.service';
import { WarehouseService } from '../../../services/warehouse.service';
import { InventoryService } from '../../../services/inventory.service';
import { CertificationService } from '../../../services/certification.service';
import { OriginService } from '../../../services/origin.service';
import { Inventory } from '../../../models/inventory';
import { Certification } from '../../../models/certification';
import { CheckboxItem } from '../../../models/checkbox-item';

@Component({
  selector: 'app-inventory-edit',
  templateUrl: './inventory-edit.component.html',
  styleUrls: ['./inventory-edit.component.css'],
  providers: [UserService, ProductorService, WarehouseService, InventoryService, OriginService, CertificationService]
})
export class InventoryEditComponent implements OnInit {
  @ViewChild('ref') ref;

  public titulo: string;
  public productor: any = {};
  public warehouse: any = {};
  public inventory: any = {};
  public origin: any = {};
  public gallons = false;
  public cert: any = {};
  public user: any = {};
  public certs: Certification[];
  public certify: Certification[];
  public identity;
  public token;
    public damage = 0;
  public discoinDamage = 0;
  e_factor = false;
  public url: string;
  public alertMessage;
  public tara: number;
  public harvest;
  public price;
  public isChecked = [];
  public nameCF;
  public varieties: string[] = ['Catuaí', 'Catucai Amarillo', 'Caturra', 'Bourbón', 'ihcafe 90', 'Parainema',
  'Typica', 'Lempira', 'Pacas', 'Obata', 'Colombiano', 'Geisha', 'Icatu', 'Magarogipe', 'Blend', 'Catimor',
   'SL20', 'SL25', 'SL28', 'Catuai Rojo','Java', 'Anacafe 14', 'Paca Nanca', 'Blend', 'Borbon Rosado', 'Pacamara',
  'Aji Natural', 'Bourbon Sidra', 'Wush Wush', 'Chiroso', 'Laurina', 'Sudan Rume', 'Sidra', 'Bernardina', 'Moka', 'Castilla', 'Purpurasea', 'Heirloom'  ];

  public sacos;
  public tsacos: number;
  public desc: number;
  public descuentoHumedad: number;
  public valor: number;
  public flet: number;
  public certss: any;

  public tare;
  public total_tara;
  public desc_humedad;
  public gross_total_lb;
  public total_after_discount;
  public total_discount;
  public total_net_lb;
  public total_net_qq;
  public gross_total_q;
  public total_net_green;
  public total_net_qqq;
  public inventoryDetails = [];
  public CFname;
  public WHname;
  public OGname;
  public factor;
  public tareTroco = 0;

  myControl: FormControl = new FormControl();
  myControlCF: FormControl = new FormControl();
  myControlOrigin: FormControl = new FormControl();

  public availableWarehouse = [];
  public availableCoffeeFarmer = [];
  public availableOrigin = [];
  public house_select = [];
  public filteredOptions: Observable<any[]>;
  public filteredOptionsCF: Observable<any[]>;
  public filteredOptionsOrigin: Observable<any[]>;
  public selectedWarehouse = null;
  public selectedCoffeeFarmer = null;
  public selectedOrigin = null;
  public selectedName = '';
  public selectedNameCF = '';
  public selectedNameOrigin = '';
  public availableCerts = [];
  public select = new FormControl();
  public varietyS = new FormControl();
public gals;
  interestFormGroup: FormGroup;
  interests: any;
  selected: any;

  states = [
    { value: 'Seco', viewValue: 'Seco' },
    { value: 'Uva', viewValue: 'Uva' },
    { value: 'Mojado', viewValue: 'Mojado' },
    { value: 'Húmedo', viewValue: 'Húmedo' },
    { value: 'Verde', viewValue: 'Verde' },
    { value: 'Resaca', viewValue: 'Resaca' },
    { value: 'StockLot', viewValue: 'StockLot' },
    { value: 'Oro Exportable', viewValue: 'Oro Exportable' },
    { value: 'Galones', viewValue: 'Galones' }
  ];

  receptions = [
    { value: 'Pergamino', viewValue: 'Pergamino' },
    { value: 'Uva', viewValue: 'Uva' },
    { value: 'Oro Exportable', viewValue: 'Oro Exportable' }
  ];

  processCoffee = [
    { value: 'Lavado', viewValue: 'Lavado' },
    { value: 'Lavado Reposado', viewValue: 'Lavado Reposado' },
    { value: 'Natural', viewValue: 'Natural' },
    { value: 'Natura Reposado 48 horas', viewValue: 'Natural Reposado 48 horas' },
    { value: 'Natura Reposado 72 horas', viewValue: 'Natural Reposado 72 horas' },
    { value: 'Natura Reposado 90 horas', viewValue: 'Natural Reposado 90 horas' },
    { value: 'Melado', viewValue: 'Melado' },
    { value: 'Semi Melado', viewValue: 'Semi Melado' },
    { value: 'Melado Rojo', viewValue: 'Melado Rojo' },
    { value: 'Anaeróbico 48 Horas', viewValue: 'Anaeróbico 48 Horas'},
    { value: 'Anaeróbico 80 Horas', viewValue: 'Anaeróbico 80 Horas'},
    { value: 'Anaeróbico 72 Horas', viewValue: 'Anaeróbico 72 Horas'},
  ];

  entrys = [
    { value: 'Propio', viewValue: 'Propio' },
    { value: 'Depósito', viewValue: 'Depósito' }
  ];

  isDisabled = false;
  form = new FormGroup({
    'cb1': new FormControl({ value: 0, disabled: false }),
    'cb2': new FormControl({ value: 0, disabled: false })
  });

  public inventoryModel = {
    certs: []
  };
  certsOptions = new Array<CheckboxItem>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _productorService: ProductorService,
    private _warehouseService: WarehouseService,
    private _inventoryService: InventoryService,
    private _originService: OriginService,
    private _certificationService: CertificationService,
    fb: FormBuilder,
    private formBuilder: FormBuilder,
    private _fb: FormBuilder,
  ) {
    this.titulo = 'Editar Nota de Peso';
    this.identity = this._userService.getCompany();
    this.user = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.inventory = {} as Inventory;
    this.url = GLOBAL.url;
    this.form.get('cb1').disable();
    this.form.get('cb2').disable();
  }

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());

  ngOnInit() {
    this.getCertifications();
    this.interestFormGroup = this.formBuilder.group({
      certs: this.formBuilder.array([])
    });
    this.getInventory();
    this.harvest = this.identity.harvest;
    this.price = this.identity.price;
    this.identity = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.inventory.entry = 'Depósito';
    /*------------------Autocompletes--------------------*/
    this._warehouseService.getWarehouses().subscribe(warehouse => {
      this.availableWarehouse = warehouse;
    });

    this._productorService.getProductores().subscribe(productor => {
      this.availableCoffeeFarmer = productor;
    });

    this._originService.getOrigins().subscribe(origin => {
      this.availableOrigin = origin;
    });

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filter(val))
      );

    this.filteredOptionsCF = this.myControlCF.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterCF(val))
      );

    this.filteredOptionsOrigin = this.myControlOrigin.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterOrigin(val))
      );

    /*------------------/Autocompletes--------------------*/

    setTimeout(() => {
      this.certsOptions = this.certs.map(x => new CheckboxItem(x.cod, x.name));
    }, 1000);
  }
  onCertsChange(label) {
    this.inventoryModel.certs = label;
    console.log('Certification:', this.inventoryModel.certs);
  }
  getCertifications() {

    this._certificationService.getCertifications().subscribe(
      (res) => {
        if (!res) {
          this._router.navigate(['/']);
        } else {
          console.log('Certificaciones: ', res);
          this.certs = res;
          this.certify = res.map(element => element.name);
          const houses: string[] = this.certs.map(x => x.house);
          this.house_select = _.uniq(houses);
          console.log('certs: ', this.house_select);
          this.availableCerts = res.map(element => element.name);
          // this.certs = res.map(element => element.name);
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }
  /*------------------Autocompletes--------------------*/
  filter(val: any): any[] {
    return this.availableWarehouse.filter(warehouse =>
      warehouse.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }
  filterCF(val: any): any[] {
    return this.availableCoffeeFarmer.filter(productor =>
      productor.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }
  filterOrigin(val: any): any[] {
    return this.availableOrigin.filter(origin =>
      origin.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
  }

  /*------------------/Autocompletes--------------------*/

  onChange(event) {
    const certs = <FormArray>this.interestFormGroup.get('certs') as FormArray;

    if (event.checked) {
      certs.push(new FormControl(event.source.value));
    } else {
      const i = certs.controls.findIndex(x => x.value === event.source.value);
      certs.removeAt(i);
    }
    console.log(this.interestFormGroup.value);
  }

  /*------------------------ lista de peso -----------------------------*/
  calculateTara(newVal) {
    this.sacos = newVal;
    this.total_tara = this.tare * this.sacos;
  }

  tareTrocoChange(value: number) {
    console.log('troco', value);
    this.total_tara = Number(this.tare * this.sacos) + value;
  }

  calculateTNA(val) {
    this.damage = val;
    this.calculateTNL(this.desc_humedad);
  }
  calculateGals(val) {
    this.gross_total_q = +val;
    this.total_net_lb = +val;
    this.total_net_qq = +val;
    this.total_net_lb = +val;
    this.total_net_green = +val;
  }
  calculateTNL(Val) {
    this.desc_humedad = Val;
    this.total_discount = 0;
    this.inventory.desc_humedad = this.desc_humedad;
    this.descuentoHumedad = this.desc_humedad / 100;
    this.discoinDamage = this.damage / 100;
    const libras_brutas = this.gross_total_lb - this.total_tara;
    const q_brutos = this.gross_total_lb * 0.0045;
    const tara_qq: number = this.total_tara * 0.0045;
    const totalgrossqq = q_brutos - tara_qq;
    if (this.damage > 0) {
     const grossPercent = totalgrossqq * this.discoinDamage;
     const gross = totalgrossqq - grossPercent;
     this.gross_total_q = Math.floor(gross * 100) / 100;
    } else {
     this.gross_total_q = Math.floor(totalgrossqq * 100) / 100;
    }
    const total_neto = libras_brutas * this.descuentoHumedad;
    if (this.e_factor === true) {
      if (Number.isInteger(libras_brutas) && Number.isInteger(total_neto)) {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = total_lb - damagePercent;
        } else {
          this.total_net_lb = libras_brutas - total_neto;
        }
      } else {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = Math.floor(total_lb - damagePercent);
        } else {
          this.total_net_lb = Math.floor(libras_brutas - total_neto);
        }
      }
      if (this.identity._id === '5f80c3091052d10488531338') {
        const totalqq = this.total_net_lb / this.factor;
        this.total_net_qq = Math.floor(totalqq * 100) / 100;
      } else {
        this.total_net_qq = this.total_net_lb / this.factor;
      }
      // this.total_net_qq = Math.floor(totalqq * 100) / 100;
      this.total_net_green = this.total_net_qq / 1.25;
      if (this.identity._id === '61aff09e476d4e0bc705d23d') {
        this.total_net_qq = this.total_net_lb / this.factor;
        this.gross_total_q = this.total_net_lb / 100;
        console.log(this.gross_total_q);
        this.total_net_green = this.total_net_qq / 1.22;
      }
    } else {
      if (Number.isInteger(libras_brutas) && Number.isInteger(total_neto)) {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = total_lb - damagePercent;
        } else {
          this.total_net_lb = libras_brutas - total_neto;
        }
      } else {
        if (this.damage > 0) {
          const total_lb = libras_brutas - total_neto;
          const damagePercent = total_lb * this.discoinDamage;
          this.total_net_lb = Math.floor(total_lb - damagePercent);
        } else {
          this.total_net_lb = Math.floor(libras_brutas - total_neto);
        }
      }
      if (this.identity._id === '61aff09e476d4e0bc705d23d') {
        this.gross_total_q = (this.gross_total_lb - this.total_tara) / 100;
        console.log(this.gross_total_q);
        this.total_net_qq = this.total_net_lb / 100;
        this.total_net_green = this.total_net_qq / 1.22;
      } else {
        if (this.identity._id === '5f80c3091052d10488531338') {
          const totalqq = this.total_net_lb / 100;
          this.total_net_qq = Math.floor(totalqq * 100) / 100;
        } else {
          this.total_net_qq = this.total_net_lb / 100;
        }
        this.total_net_green = this.total_net_qq / 1.25;
      }
    }
  }
    flete(flete) {
    if (flete > 0) {
      const costo = this.inventory.gross_total_q * flete;
      this.inventory.flete = costo.toFixed(2);
    } else {
      this.inventory.flete = 0;
    }
  }
  getTotalLB(arr) {
    this.inventory.gross_total_lb = arr.reduce((sum, curr) => sum + curr.gross_total_lb, 0);
    return arr.reduce((sum, curr) => sum + curr.gross_total_lb, 0);
  }
  getTotalTara(arr) {
    this.inventory.tare = arr.reduce((sum, curr) => sum + curr.total_tara, 0);
    return arr.reduce((sum, curr) => sum + curr.total_tara, 0);
  }
  getTotalLb(arr) {
    this.inventory.total_net_lb = arr.reduce((sum, curr) => sum + curr.total_net_lb, 0);
    return arr.reduce((sum, curr) => sum + curr.total_net_lb, 0).toFixed(2);
  }
  getTotalBags(arr) {
    this.inventory.bags = arr.reduce((sum, curr) => sum + curr.sacos, 0);
    return arr.reduce((sum, curr) => sum + curr.sacos, 0);
  }
  getTotalGrossQ(arr) {
    const xtract = _.sumBy(arr, 'gross_total_q');
    if (this.identity._id === '61aff09e476d4e0bc705d23d') {
      this.inventory.gross_total_q = xtract.toFixed(2);
      return this.inventory.gross_total_q;
    } else {
      this.inventory.gross_total_q = xtract.toFixed(2);
      return this.inventory.gross_total_q;
    }
  }
  getTotalGreen(arr) {
    const xtract = _.sumBy(arr, 'total_net_green');
    if (this.identity._id === '61aff09e476d4e0bc705d23d') {
      this.inventory.total_net_green = xtract.toFixed(2);
      return this.inventory.total_net_green;
    } else if (this.identity._id === '674de0aa60f33d8f8a58d14b' && this.inventory.state === 'Húmedo') {
      this.inventory.total_net_green = (this.inventory.total_net_qq / 120).toFixed(2);
      return this.inventory.total_net_green;
    } else {
      this.inventory.total_net_green = xtract.toFixed(2);
      return this.inventory.total_net_green;
    }
  }
  getTotalqq(arr) {
    const xtract = _.sumBy(arr, 'total_net_qq');
    if (this.identity._id === '61aff09e476d4e0bc705d23d') {
      this.inventory.total_net_qq = xtract.toFixed(2);
      this.total_net_qqq = this.inventory.total_net_qq;
      return this.inventory.total_net_qq;
    } else {
      this.inventory.total_net_qq = xtract.toFixed(2);
      this.total_net_qqq = this.inventory.total_net_qq;
      return this.inventory.total_net_qq;
    }

  }
  getState(state) {
    this.tare = 0;
    if (this.identity._id === '5ff5e3213545ae31b8d199b0') {
      if (state === 'Mojado') {
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.valor = 0;
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.valor = 180;
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.valor = 100;
        this.tare = 1;
        this.e_factor = false;
      }
    } else if (this.identity._id === '5fa2a50e1c9a8952e6e1bc1c') {
      if (state === 'Mojado') {
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.tare = 0.5;
        this.e_factor = false;
      }
    } else if (this.identity._id === '5d78227b91f73c2429310132') {
      if (state === 'Mojado') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.tare = 0.5;
        this.e_factor = false;
      }
    } else {
      if (state === 'Mojado') {
        this.tare = 1;
        this.e_factor = false;
      } else if (state === 'Seco') {
        this.tare = 0.5;
        this.e_factor = false;
      } else if (state === 'Uva') {
        this.tare = 1;
        this.e_factor = true;
      } else if (state === 'Húmedo') {
        this.tare = 1;
          this.e_factor = false;
      } else if (state === 'Galones') {
        this.gallons = true;
      }
    }
  }
  getCoffeefarmerId(id) {
    this.inventory.coffeefarmer = id;
  }
  getWarehouseId(id) {
    this.inventory.warehouse = id;
  }
  getOriginId(id) {
    this.inventory.origin = id;
  }
  asignT(totalqq) {
    this.inventory.total_net_qq = totalqq;
  }
  asignlb(totallb) {
    this.inventory.total_net_lb = totallb;
  }
  asigntd(totaltd) {
    this.inventory.total_discount = totaltd;
  }
  asignTara(tara) {
    this.inventory.tare = tara;
  }
  asignLB(totaLB) {
    this.inventory.gross_total_lb = totaLB;
  }

  addDetail() {
    const newDetail: any = {
      tare: this.tare,
      sacos: this.sacos,
      gross_total_lb: Math.floor(this.gross_total_lb),
      total_tara: this.total_tara,
      factor: this.factor,
      desc_humedad: this.desc_humedad,
      damage: this.damage,
      total_net_lb: this.total_net_lb,
      total_net_qq: this.total_net_qq,
      gross_total_q: this.gross_total_q,
      total_net_green: this.total_net_green,
    };
    if (
      this.identity._id === '616661122f820561d402aecf' || // * Aruco
      this.identity._id === '5d7742116bc1d64060abfc9b'    // * Info
    ) newDetail.tareTroco = this.tareTroco ? this.tareTroco : 0;
    this.inventoryDetails.push(newDetail);
    this.tareTroco = 0;
    this.factor = 0;
    this.damage = 0;
    this.desc_humedad = 0;
    this.sacos = 0;
  }
  deleteFile(inventoryDetail) {
    this.inventoryDetails = this.inventoryDetails.filter(t => t.tare !== inventoryDetail.tare);
  }
  /*-------------------------------------------------*/
  getInventory() {
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._inventoryService.getInventory(id).subscribe(
        response => {
          if (!response) {
            this._router.navigate(['/']);
          } else {
            this.inventory = response;
            if (this.inventory.state === 'Uva') {
              this.e_factor = true;
            }
            if (this.inventory.coffeefarmer) {
              this.CFname = this.inventory.coffeefarmer.name;
              this.inventory.coffeefarmer = this.inventory.coffeefarmer._id;
            } else {
              this.CFname = '';
            }
            if (this.inventory.warehouse) {
              this.WHname = this.inventory.warehouse.name;
              this.inventory.warehouse = this.inventory.warehouse._id;
            } else {
              this.WHname = '';
            }
            if (this.inventory.origin) {
              this.OGname = this.inventory.origin.name;
              this.inventory.origin = this.inventory.origin._id;
            } else {
              this.OGname = '';
            }
console.log(this.inventory);
            const stamps = this.inventory.certs;
            this.select.setValue(stamps);
            const varieties = this.inventory.varieties;
            this.varietyS.setValue(varieties);
            const certs = {
              certs: stamps
            };
            this.inventoryModel = certs;


            // this.desc_humedad = this.inventory.inventoryDetails[0].desc_humedad;

            const i_certs = this.inventory.certifications.certs;
            console.log(this.inventory.certifications.certs);
            this.inventoryDetails = this.inventory.inventoryDetails;
            this._certificationService.getCertifications().subscribe(
              (res) => {
                this.certify = res.map(element => element.name);
                this.certify.forEach((item, index) => {
                  i_certs.forEach((cert) => {
                    if (item === cert) {
                      const check = true;
                      this.isChecked.push(check);
                    }
                  });
                });
              }
            );
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            console.log(error);
          }
        }
      );
    });
  }

  enableSlideToggle() {
    this.form.get('cb1').enable();
    this.form.get('cb2').enable();
  }

  calculateVB(valor) {
    if (valor > 0) {
      const costo = this.inventory.total_net_qq * valor;
      this.inventory.cost_per_beneficiary = costo.toFixed(2);
    } else {
      this.inventory.cost_per_beneficiary = 0;
    }
  }
  onSubmit() {
    console.log('selected: ', this.select.value);
    this.inventory.certs = this.select.value;
    this.inventory.varieties = this.varietyS.value;
    this.inventory.inventoryDetails = this.inventoryDetails;
    this.inventory.harvest = this.harvest;
    const total = this.inventory.total_net_qq;
    this.inventory.total_net_qq = total;
    if (this.inventory.liquidated === true) {
      this.inventory.total_net_sett = this.inventory.total_net_qq;
    }
    if (this.inventory.dried === false) {
      this.inventory.total_qq_dry = this.inventory.total_net_qq;
    }

    if (this.identity._id === '643d773bfebd7823442022cb') {
      this.inventory.total_net_sett = this.inventory.total_net_lb;
    } else if (this.identity._id === '674de0aa60f33d8f8a58d14b') {
      this.inventory.total_net_sett = this.inventory.total_net_green;
    }
    // this.inventory.total_net_sett = this.inventory.total_net_qq;
/*     if (this.user._id === '616ae7de37fec53080d8035f') {
      this.inventory.total_qq_dry = this.inventory.total_net_qq;
      this.inventory.total_net_sett = this.inventory.total_net_qq;
    } */
    this.inventory.price = this.price;
    this.inventory.gallons = this.gallons;
    this.inventory.microlot = this.inventory.microlot;
    this.inventory.gallons = this.gallons;
    console.log(this.inventory);
    this._route.params.forEach((params: Params) => {
      const id = params['id'];

      this._inventoryService.editInventory(id, this.inventory).subscribe(
        response => {
          if (!response) {
            this.alertMessage = 'Error en el servidor';
          } else {
            this.alertMessage = 'Nota de Peso actualizada correctamente';
            // this.origin = response.origin;
            // console.log(this.origin);
            this._router.navigate(['/notas-de-peso']);
          }
        },
        error => {
          const errorMessage = <any>error;
          if (errorMessage != null) {
            const body = JSON.parse(error._body);
            this.alertMessage = body.message;
            console.log(error);
          }
        }
      );

    });
  }


}
