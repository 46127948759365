import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger, MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoffeeFarmer } from '../../../models/productor';
import { Farmer } from '../../../models/farmer';
import { IhcafeVoucher } from '../../../models/ihcafe-voucher';
import { IhcafeVoucherService } from '../../../services/ihcafe-voucher.service';
import { ProductorService } from '../../../services/productor.service';
import { FarmerService } from '../../../services/farmer.service';
import { SettlementService } from '../../../services/settlement.service';
import { UserService } from '../../../services/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-ihcafe-voucher',
  templateUrl: './ihcafe-voucher.component.html',
  styleUrls: ['./ihcafe-voucher.component.css']
})
export class IhcafeVoucherComponent implements OnInit {
  public form: FormGroup;
  public harvests = ['2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025'];
  public filteredCoffeefarmers: CoffeeFarmer[] = [];
  private coffeefarmers: CoffeeFarmer[] = [];
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private coffeefarmerVoucherService: IhcafeVoucherService,
    private coffeefarmerService: ProductorService,
    private farmerService: FarmerService,
    private _settlementService: SettlementService,
    private userService: UserService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<IhcafeVoucherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.createForm();
    this.listenerFormValues();
    console.log('data: ', this.data);
    const currentHarvest = this.userService.getCompany().harvest;
    this.form.get('harvest').setValue(currentHarvest);
  }

  listenerFormValues(): void {
    this.form.get('total_qq_gold').valueChanges.subscribe((value: number) => {
      this.form.get('total_qq').setValue(value * 1.25);
    });
  }

  selectCoffeefarmer(): void {
    const code = this.form.get(['ihcafe']).value;
    console.log('selected: ', code);
    this.farmerService.getFarmerPerCode(code).subscribe((res: Farmer) => {
      console.log(res);
      this.form.get('total_qq').setValue(this.data.total_qq);
      this.form.get('coffeefarmer').setValue(res[0].coffeefarmer);
      this.form.get('municipality').setValue(res[0].municipio_farmer);
      this.form.get('department').setValue(res[0].departme_farmer);
      this.form.get('farmMunicipality').setValue(res[0].farm_municipality);
      this.form.get('farmDepartment').setValue(res[0].farm_deparment);
      this.form.get('rtn').setValue(res[0].rtn);
      this.form.get('ihcafe').setValue(code);
      this.form.get('telephone').setValue(res[0].telefono1);
      const total_settled = +this.data.total.toFixed(2);
      this.form.get('total_qq').setValue(this.data.total_qq);
      this.form.get('total_qq_gold').setValue(this.data.total_qq / 1.25);
      this.form.get('total_settled').setValue(total_settled);
    })
  }

  submit(): void {
    const voucher: IhcafeVoucher = this.form.getRawValue();
    console.log('form: ', voucher);
    voucher.total_qq = this.form.get('total_qq_gold').value * 1.25;
    // voucher.collated = true;
    voucher.liquidation = this.data._id;
    this.coffeefarmerVoucherService.createVoucher(voucher).subscribe(response => {
      console.log('Comprobante creado: ', response);
      const settlement: any = {
        ihcafe: true
      }
      this._settlementService.updateSettlement(this.data._id, settlement).subscribe(response => {
        console.log('Liquidacion updated: ', response);
      }, err => console.error('Error al anular liquidacion: ', err));
      this.dialogRef.close();
    }, err => console.error('Error al crear comprobante: ', err));
  }

  createForm(): void {
    this.form = this.fb.group({
      address:          ['', Validators.required],
      date:             [new Date(), Validators.required],
      coffeefarmer:     [null, Validators.required],
      department:       ['', Validators.required],
      farmLocation:     ['', Validators.required],
      farmDepartment:   ['', Validators.required],
      harvest:          ['', Validators.required],
      ihcafe:           ['', Validators.required],
      municipality:     ['', Validators.required],
      farmMunicipality: ['', Validators.required],
      rtn:              ['', Validators.required],
      total_qq:         [0, Validators.required],
      total_settled:    [0, Validators.required],
      total_qq_gold:    [0, Validators.required],
      telephone:        ['', Validators.required],
      voucher:          ['', Validators.required],
      observations:     [''],
    });
  }

}
