import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleRedirectGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(): boolean {
    const userRole = this.userService.getIdentity();

    if (userRole) {
        if (userRole.userType.description === 'MAP') {
        this.router.navigate(['/checkins']);
        return false;
        } else if (userRole.userType.description === 'WEB') {
        this.router.navigate(['/notas-de-peso']);
        return false;
        } else if (userRole.userType.description === 'IMP') {
        this.router.navigate(['/geolistas']);
        return false;
        }
    }

    return true;
  }
}
