import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Machine } from '../../../../models/machine';
import { Warehouse } from '../../../../models/warehouse';

import { UserService } from '../../../../services/user.service';
import { MachineService } from '../../../../services/machine.service';
import { WarehouseService } from '../../../../services/warehouse.service';
@Component({
  selector: 'app-drieds-filter',
  templateUrl: './drieds-filter.component.html',
  styleUrls: ['./drieds-filter.component.css'],
  providers: [MachineService, WarehouseService]
})
export class DriedsFilterComponent implements OnInit {
  public form: FormGroup;
  public machineData: Machine[];
  public warehouseData: Warehouse[];
  public statusData = ['Proceso', 'Secando', 'Descargado', 'Finalizado'];
  public harvests = ['2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025', 'Todas'];

  constructor(
    private fb: FormBuilder,
    private warehouseService: WarehouseService,
    private machineService: MachineService,
    public dialogRef: MatDialogRef<DriedsFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.createForm();
    this.form.patchValue(this.data);
    this.getDataFromService();
  }

  createForm(): void {
    this.form = this.fb.group({
      cod:        [''],
      date_gte:   [''],
      date_lte:   [''],
      warehouse:  [''],
      machine:    [''],
      status:     [''],
      harvest:    ['']
    });
  }

  sendData(): void {
    const data = this.form.getRawValue();
    Object.keys(data).forEach(key => {
      const current = data[key];
      if (current === '' || current === null || current === undefined || current === 'Todas') {
        delete data[key];
      }
    });
    console.log('Data to filter: ', data);
    this.dialogRef.close({apply: true, data: data});
  }

  getDataFromService(): void {
    this.warehouseService.getWarehouses().subscribe(response => {
      this.warehouseData = response;
    }, err => console.error('Error al obtener las bodegas: ', err));

    this.machineService.getMachines().subscribe(response => {
      this.machineData = response;
    }, err => console.error('Error al obtener las maquinas: ', err));
  }

}
