import { FormGroup, FormBuilder } from '@angular/forms';
import { ProductorService } from './../../../services/productor.service';
import { CoffeeFarmer } from './../../../models/productor';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdvancePaymentService } from '../../../services/advancePayment.service';
import { CollectionCenter } from '../../../models/collectionCenter';
import { CollectionCenterService } from '../../../services/collectionCenter.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatDialog, MatPaginator } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../../services/excel.service';
import * as _ from 'lodash';

import { PaymentService } from '../../../services/payment.service';
import { PrintService } from '../../../services/print.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';


import { SeatAddModalComponent } from '../../seat-add-modal/seat-add-modal.component';
// import { PaymentsEditComponent } from '../../payments-edit/payments-edit.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

import { Payment } from '../../../models/payment';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PaymentsComponent implements OnInit {
  public form: FormGroup;
  public title = 'Abonos';
  public dataLen = 0;
  public limit = 100;
  public loading = false;
  public page = 0;
  public currency;
  public identi;
  public harvests = ['2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025'];
  public currentHarvest = '';
  public groupPayments = JSON.parse(JSON.stringify({}));
  public filteredCoffeefarmers: CoffeeFarmer[] = [];
  public availableCoffeefarmers: CoffeeFarmer[] = [];
  public availablecashCounts: CollectionCenter[];
  public filteredcashCounts: CollectionCenter[];
  public convertToExcel = [];
  public pipe = new DatePipe('es-HN');
  public paymentsData: Payment[] = [];
  public company: any;
  public displayedColumns: string[] = ['cod', 'date', 'coffeefarmer', 'advancePayment', 'payment',
   'mount', 'outstanding_balance', 'observations', 'action'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public alertMessage = '';
  public role;

  constructor(
    private _userService: UserService,
    private _paymentService: PaymentService,
    private _advancePaymentService: AdvancePaymentService,
    private _coffeeFarmerService: ProductorService,
    private _cashCountService: CollectionCenterService,
    private _printService: PrintService,
    private excelService: ExcelService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.identi = this._userService.getIdentity();
    this.company = this._userService.getIdentity().company;
    this.role = this._userService.getIdentity().userType.description;
  }

  ngOnInit() {
    this.createForm();
    this.listeners();
    this.currency = GLOBAL.currency;
    this.currentHarvest = this.identi.company.harvest;
    if (!this.harvests.some(h => h === this.currentHarvest)) {
      this.harvests.push(this.currentHarvest);
    }
    this.form.get('harvest').setValue(this.currentHarvest);
    this.getCoffeefarmers();
    this.getCashCounts();
    this.getPaymentsWithFilters();
  }

  shouldShowContent(): boolean {
    const restrictedCompanies = ['5fa2a50e1c9a8952e6e1bc1c', '643d773bfebd7823442022cb'];

    if (!restrictedCompanies.includes(this.company._id)) {
      return true;
    }

    return this.role === 'ADMIN' && restrictedCompanies.includes(this.company._id);
  }

  getCoffeefarmers(): void {
    this._coffeeFarmerService.getProductores().subscribe(response => {
      this.availableCoffeefarmers = response as CoffeeFarmer[];
      this.filteredCoffeefarmers = response as CoffeeFarmer[];
    });
  }

  getCashCounts(): void {
    this._cashCountService.getCollectionCenters().subscribe(res => {
      this.availablecashCounts = res as CollectionCenter[];
      this.filteredcashCounts = res as CollectionCenter[];
    }, err => console.error('Error al obtener agencias', err));
  }

  getPaymentsWithFilters(): void {
    const data = this.getFilterData();
    this._paymentService.getPaymentsWithFilters(data).subscribe(res => {
      this.dataLen = res.count;
      this.paymentsData = res.items;
      // console.log('items', _.groupBy(res.items));
    }, err => console.error('Error al obtener abonos filtrados: ', err));
  }

  listeners(): void {
    this.form.get('coffeefarmerName').valueChanges.subscribe((value: string) => {
      this.filteredCoffeefarmers = this.availableCoffeefarmers
        .filter(coffeefarmer => String(coffeefarmer.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('collectionCenterS').valueChanges.subscribe((value: string) => {
      this.filteredcashCounts = this.availablecashCounts
        .filter(cash => String(cash.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });
  }

  getFilterData(): any {
    const filters = _.cloneDeep(this.form.getRawValue());
    this.loading = true;
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '' || (key === 'harvest' && filters[key] === 'Todas')) {
        delete filters[key];
      }
    });
    delete filters['coffeefarmerName'];
    filters['skip'] = this.page * this.limit;
    filters['limit'] = this.limit;
    console.log('filters : ', filters);
    return filters;
  }

  changePage(paginator: MatPaginator) {
    console.log('Event: ', paginator);
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTablePayments');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getPaymentsWithFilters();
  }

  filterData(): void {
    this.page = 0;
    this.paginator.firstPage();
    this.getPaymentsWithFilters();
  }

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');

  openDialogSeat(payment: Payment): void {
    payment['tag'] = 'Abono';
    const dialogRef = this.dialog.open(SeatAddModalComponent, {
      height: '700px',
      width: '900px',
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      data: payment
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPaymentsWithFilters();
      }
    });
  }

  openDialogD(payment) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar abono',
        title: '',
        text: `¿Seguro que quieres eliminar el abono <strong>#${payment.cod}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.cancelPayment(payment);
        this.getPaymentsWithFilters();
      }
    });
  }

  print(payment: any, skip = true) {
    console.log('toPrintPayment: ', payment);
    const group = payment.fullbalance ? true : false;
    !payment.fullbalance ?
      this._printService.getPayment(payment._id) :
      this._printService.getPaymentBalance(payment.fullbalanceId, payment.coffeefarmer._id);
  }

  /*
    * Recalcular los intereses acumulados hasta la fecha que se fue anulado.
    * pd: Aun si se borrar un abono en cualquier punto, se debe recalcular todo nuevamente.
    @param payment: El abono que se va anular.
    @return Promise debido a procesos asincronos.
  */
  cancelPayment (payment: Payment): void {
    const paymentToCancel = payment;
    if (paymentToCancel.liquidation === null || paymentToCancel.liquidation === undefined) {
      this.alertMessage = '';
      paymentToCancel.enabled = false;
      this._paymentService.editPayment(paymentToCancel._id, paymentToCancel).subscribe((response) => {
        console.log('Abono anulado: ', response);
        setTimeout(() => {
          this._advancePaymentService.recalculateAdvancePayment(payment.advancePayment._id);
        }, 1000);
      });
    } else {
      this.alertMessage = 'El abono no puede anularse, pertenece a una liquidacion';
      setTimeout(() => this.alertMessage = '', 5000);
    }
  }

    exportAsXLSX(): void {
      let filterDataValues: any = {};
    filterDataValues['skip'] = 0;
    filterDataValues['limit'] = 100000;
    filterDataValues.enabled = true;
    const data = this.getFilterData();
    this._paymentService.getPaymentsWithFilters(data).subscribe(res => {
      this.dataLen = res.count;
      this.paymentsData = res.items;
        if (!res) {
        } else {
          this.convertToExcel = res.items;
          this.convertToExcel.forEach((element, i) => {
            const newDate = this.pipe.transform(element.date, 'shortDate');
            if (element === undefined) {
              console.error('Undefined element: ', element, i);
            }

              const name = element.hasOwnProperty('coffeefarmer') ?
                ( element.coffeefarmer !== null ? element.coffeefarmer.name : '') : '';

              const antipo = element.hasOwnProperty('advancePayment') ?
                ( element.advancePayment !== null ? element.advancePayment.cod : '') : '';

              const rows = {
                Código: element.cod,
                Fecha: newDate,
                Productor: name,
                Monto: element.mount ? element.mount.toFixed(2) : '',
                Capital_pagado: element.capital ? element.capital.toFixed(2) : '',
                Interes_Pagado: element.interest_paid ? element.interest_paid.toFixed(2) : '',
                Pago: element.payment ? element.payment.toFixed(2) : '',
                Saldo: element.outstanding_balance ? element.outstanding_balance.toFixed(2) : '',
                Observaciones: element.observations,
                Cosecha: element.harvest,
                Anticipo: antipo,
              };

              const convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
              this.convertToExcel = convertToExcel;

          });
        }
        this.excelService.exportAsExcelFile(this.convertToExcel, 'Abonos');
      },
      error => {
          console.error('err', error);
      }
    );
  }

  /*
  * Se ejecuta con el texto especifico en el input de filtrar tabla, obtiene todos los abonos del perfil actual
  * para poder recalcularlos y estable la fecha de creacion a los abonos si una fecha establecida.
  @param status: Define si el recalculo va ser local(false) o tambien a la BD (true).
  @return void.
  */
  updatePaymentsFromAP(status = false): void {
    this._paymentService.getEnabledPayments().subscribe((response) => {
      const grouped = _.groupBy(response.filter(item => (item.enabled === true || !item.hasOwnProperty('enabled'))
        && item.payment_status !== 'Pagado'), 'advancePayment._id');
      console.log('grouped payments per AP: ', grouped);
      for (let key_ = 0; key_ < Object.keys(grouped).length; key_ ++ ) {
        const key = Object.keys(grouped)[key_];
        if (key !== 'undefined' && key !== undefined) {
          const pre_payments = grouped[key].filter(item => item.advancePayment !== undefined &&
            item.advancePayment !== null);
          pre_payments.forEach((item: any) => {
            if (item === undefined) {
              console.error('item undefined: ', item);
            }
            if (!item.hasOwnProperty('date')) {
              console.error('Abono sin fecha: ', item);
              item.date = item.createdAt;
            }
        });
          const payments = pre_payments.sort((n1, n2) => new Date(n1.date).getTime() -
            new Date(n2.date).getTime());

          console.log(`======================= ANTICIPO ${key_ + 1}`, payments);
          this._advancePaymentService.recalculateAdvancePayment(payments[0].advancePayment._id, status);
          const undef = grouped[key].filter(item => item.advancePayment === null || item.advancePayment === undefined);
          console.error('Abonos sin anticipos: ', undef);
        } else {
          console.error('Key undefined, ya no existe el anticipo ?'); // No tiene anticipo ?
        }
      }
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      cod:            [''],
      harvest:            [''],
      coffeefarmer:       [''],
      coffeefarmerName:   [''],
      collectionCenter:   [''],
      collectionCenterS:  [''],
      date_in:            [''],
      date_out:           [''],
      observations:       [''],
    });
  }

}
