import { Router, ActivatedRoute } from '@angular/router';
import { LotService } from '../../../../services/lot.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { Lot } from '../../../../models/lot';
import { HttpErrorResponse } from '@angular/common/http';
import { GeoListService } from '../../../../services/geoList.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit {

public form: FormGroup;
  public lots = [];
  public list = [];

  constructor(
    private fb: FormBuilder,
    private _lotService: LotService,
    private _geoListServer: GeoListService,
    public dialogRef: MatDialogRef<CreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.list = this.data.map(item => item._id);
    this.createForm();
    this.getLots();
  }

  save() {
    const list = this.form.getRawValue();
    list.geoList = this.list;
    console.log(list);
    this._geoListServer.addGeoList(list).subscribe((res: any) => {
      console.log('Lista agregada: ', res);
      this.dialogRef.close(res);
    }, (err: HttpErrorResponse) => console.error('Error: ', err));
  }

  private createForm(): void {
    this.form = this.fb.group({
      lot:        [''],
      name:      ['', Validators.required],
      lotName: [''],
      geoList:       ['']
    });
  }

  getLots() {
    this._lotService.getLots().subscribe(
      response => {
        this.lots = response;
      }
    );
  }

}
