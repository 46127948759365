import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class GeoListService {

  public url = GLOBAL.url + '/geolist';
  constructor(
    private http: HttpClient
  ) { }

  addGeoList(geoList: any): Observable<any> {
    return this.http.post(`${this.url}/new`, geoList);
  }

  getList(): Observable<any> {
     return this.http.get(`${this.url}/list`);
   }

   getGeoList(geoListId: string): Observable<any> {
     return this.http.get(`${this.url}/${geoListId}`);
   }

   updateGeoList(geoList: any): Observable<any> {
     return this.http.put(`${this.url}/${geoList._id}`, geoList);
   }
   
   remove(id: any): Observable<any> {
     return this.http.delete(`${this.url}/${id}`);
   }
}
