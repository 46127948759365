import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';

import { UserService } from '../../../services/user.service';
import { ProductorService } from '../../../services/productor.service';
import { InventoryService } from '../../../services/inventory.service';
import { SettlementService } from '../../../services/settlement.service';
import { AdvancePaymentService } from '../../../services/advancePayment.service';
import { CoffeeFarmer } from '../../../models/productor';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { ProductorAddComponent } from '../productor-add/productor-add.component';
import { ProductorEditComponent } from '../productor-edit/productor-edit.component';
import { PrintService } from '../../../services/print.service';
import { ImportDataService } from '../../../services/import-data.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { ExcelService } from '../../../services/excel.service';
import { OriginService } from '../../../services/origin.service';

@Component({
  selector: 'app-productores',
  templateUrl: './productores.component.html',
  providers: [UserService, ProductorService, InventoryService, SettlementService, AdvancePaymentService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./productores.component.css'],
})

export class ProductoresComponent implements OnInit {

  public titulo: string;
  public productores: CoffeeFarmer[];
  public identity;
  public company: any;
  public token;
  public url: string;
  public confirmado;
  public alertMessage = '';
  public limit = 0;
  public leCoffeefarmers = 0;
  public filterDataValues: any = {};
  public currentHarvest = '';
  closeResult: string;
  data: any[] = [];
  public productor: any = {};
  private selectedFile: File = null;
  public dataTable: CoffeeFarmer[];

  displayedColumns: string[] = ['cod', 'name', 'ihcafe', 'rtn', 'address', 'telephone', 'n_id', 'cod_cert', 'status', 'actions' ];
  highlightedRows = [];
  public dataSource = new TableVirtualScrollDataSource<CoffeeFarmer>();
  @ViewChild( CdkVirtualScrollViewport ) viewport: CdkVirtualScrollViewport;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort)set matSortSetter(value: MatSort) {
    if (this.dataSource && value) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private _productorService: ProductorService,
    private _inventoryService: InventoryService,
    private _advancePaymentService: AdvancePaymentService,
    private _settlementService: SettlementService,
    private _printService: PrintService,
    private _userService: UserService,
    private _importDataService: ImportDataService,
    private excelService: ExcelService,
    public dialog: MatDialog

  ) {
    this.titulo = 'Productores';
    this.company = this._userService.getCompany();
    this.productor = {} as CoffeeFarmer;
    this.currentHarvest = this.company.harvest;
    this.filterDataValues['harvest'] = this.currentHarvest;
  }

  ngOnInit() {
    this.getProductores();
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
  }

  openDialog() {
    const dialogRef = this.dialog.open(ProductorAddComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProductores();
    });
  }

  openDialogD(productor: CoffeeFarmer) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar productor',
        title: '',
        text: `¿Seguro que quieres eliminar a <br><strong>${productor.name}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteCustomer(productor._id);
        this.getProductores();
      }
    });
  }

  openDialogEdit(productor) {
    const dialogRef = this.dialog.open(ProductorEditComponent, {
      width: '500px',
      data: productor
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.getProductores();
      }
    });
  }

  printAccountStatus(coffeefarmer: CoffeeFarmer): void {
    console.log('Coffefarmer to print: ', coffeefarmer);
    this._printService.getAccountStatus(coffeefarmer._id).then((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    })
    .catch(err => console.log('Error', err));
  }

  deleteCustomer(id) {
    console.log(id);
    this.alertMessage = '';
    this._productorService.getProductor(id).subscribe(
      res => {
        console.log(res.length);
        if (res.length !== 0) {
           this.delete(id);
        } else {
          this.alertMessage = 'el cliente no se puede borrar';
        }
      }
    );
  }

  dtodos() {
    this.data.forEach(element => {
      this.productor = element;
      this._productorService.addProductor(this.productor).subscribe(response => {
        console.log(response);
    }, error => console.error('Error al agregar productor: ', error));
    });
  }
  getProductores() {
    this._productorService.getProductores().subscribe(res => {
      this.productores = res;
      this.limit = res.length;
      this.dataSource.data = res;
      this.leCoffeefarmers = this.dataSource.data.length;
      this.dataTable = this.productores;
      }, error => console.error('Error al obtener productores: ', error)
    );
  }

  eliminarTodo() {
    this.productores.forEach(item =>{
      this._productorService.deleteProductor(item._id).subscribe(res => {
        console.log('listo!', res);
      });
    });
  }

  exportAsXLSX(): void {
    const filters = _.cloneDeep(this.filterDataValues);
    filters.skip = 0;
    filters.limit = 25000;
    this._productorService.getProductores().subscribe(res => {
      const inventories = res;
      let convertToExcel: any = inventories;
      convertToExcel.forEach((element, i) => {
        console.log(element);
          const origins = element.origins === null ? [] : element.origins.map(origin => origin.name);
          console.log(origins);
          const rows = {
            Código: element.cod,
            Fincas: origins.toString(),
            Nombre: element.name,
            Teléfono: element.telephone,
            Correo: element.email,
            Identidad: element.n_id,
            RTN: element.rtn,
            Dirección: element.address,
            IHCAFE: element.ihcafe,
          };

          console.log(rows);

          convertToExcel = Object.assign([], convertToExcel, { [i]: rows });
      });
      console.log(convertToExcel);
        this.excelService.exportAsExcelFile(convertToExcel, 'Productores');
      }, error => console.error('Error al obtener nota de peso para exportar: ', error)
    );
  }

  onDeleteProductor(id) {
    this.alertMessage = '';
    this._inventoryService.getInventoryPerCoffeefarmer(id).subscribe(
      response => {
        console.log(response.length);
          this._advancePaymentService.getAdvancePerCoffeeFarmer(id).subscribe(
            respons => {
              console.log(respons.length);
                this._settlementService.getSettlementPerCoffeeFarmer(id).subscribe(
                  res => {
                    console.log(res.length);
                    if (response.length === 0 && respons.length === 0 && res.length === 0) {
                      this.delete(id);
                    } else {
                      this.alertMessage = 'el productor no se puede borrar';
                    }
                  }
                );
            }
          );
      }
    );
  }

  delete(id) {
    this._productorService.deleteProductor(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getProductores();
        }
      }, error => console.error('Error al obtener productores: ', error)
    );
  }

  applyFilter(filterValue: string) {
    console.log('Filter: ', filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changePage(paginator: MatPaginator): void {
  }

  saveFile(file): void {
    this.selectedFile = file;
    console.log('File: ', file);
  }

  importData(): void {
    this._importDataService.importCoffeefarmers(this.selectedFile);
  }
}
