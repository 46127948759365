import { MatDialog, MatPaginator } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

import { AdvancePaymentService } from '../../../../services/advancePayment.service';
import { TestingService } from '../../../../services/testing.service';
import { PaymentService } from '../../../../services/payment.service';
import { PrintService } from '../../../../services/print.service';
import { ExcelService } from '../../../../services/excel.service';
import { UserService } from '../../../../services/user.service';
import { GLOBAL } from '../../../../services/global';

import { AdvancePaymentsPayComponent } from '../advance-payments-pay/advance-payments-pay.component';
import { AdvancePaymentEditComponent } from '../advance-payment-edit/advance-payment-edit.component';
import { AdvancePaymentAddComponent } from '../advance-payment-add/advance-payment-add.component';
import { SeatAddModalComponent } from '../../../seat-add-modal/seat-add-modal.component';
import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';

import { AdvancePayment } from '../../../../models/advancePayment';
import { Payment } from '../../../../models/payment';
import { AdvancePaymentPayBalanceComponent } from '../advance-payment-pay-balance/advance-payment-pay-balance.component';
import { PaymentAddV1Component } from '../../payments/payment-add-v1/payment-add-v1.component';

@Component({
  selector: 'app-advance-payment-list',
  templateUrl: './advance-payment-list.component.html',
  styleUrls: ['./advance-payment-list.component.css'],
})

export class AdvancePaymentListComponent implements OnInit {

  public title = 'Anticipos';
  public identi;
  public alertMessage = '';
  public convertToExcel = [];
  public pipe = new DatePipe('es-HN');
  public totalAdvancePayments = 0;
  public page = 0;
  public limit = 100;
  public form: FormGroup;
  public loading = false;
  public lenData = 0;
  public advancePaymentsData: AdvancePayment[] = [];
  public currentHarvest = '';
  public seatIndex: any = {};
  public company;
  public currency;
  public sumAproccor;
  public sumCapital;
  public capitalPayment;
  public detailObj = {
    ap: null,
    payments: []
  };
  public role;
  public filterDataValues: any = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _advancePaymentService: AdvancePaymentService,
    private _paymentService: PaymentService,
    private _printService: PrintService,
    private _testingService: TestingService,
    private _userService: UserService,
    private excelService: ExcelService,
    public dialog: MatDialog,
  ) {
    this.currentHarvest = this._userService.getCompany().harvest;
    this.identi = this._userService.getIdentity();
    this.role = this._userService.getIdentity().userType.description;
    this.filterDataValues = { harvest: this.currentHarvest, payment_status: ['Pendiente', 'Parcial'] };
  }

  ngOnInit(): void {
    this.getAdvancePaymentsWithFilters();
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
    this.currency = GLOBAL.currency;
    this.company = this.identi.company._id;
    console.log(this.currency);
    this.getSumToHarvestAproccor(this.currentHarvest);
    this.getSumToHarvest(this.currentHarvest);

    /* ! testing */
    // this._testingService.paidAdvancePaymentsValues(false);
    // this._testingService.paymentsValues();
  }

  shouldShowContent(): boolean {
    const restrictedCompanies = ['5fa2a50e1c9a8952e6e1bc1c', '643d773bfebd7823442022cb'];

    if (!restrictedCompanies.includes(this.company)) {
      return true;
    }

    return this.role === 'ADMIN' && restrictedCompanies.includes(this.company);
  }

  getAdvancePaymentsWithFilters(data?): void {
    this.filterDataValues = data !== undefined ? data : this.filterDataValues;
    this.filterDataValues['skip'] = this.page * this.limit;
    this.filterDataValues['limit'] = this.limit;
    console.log(this.filterDataValues);
    this.loading = true;
    this._advancePaymentService.getAdvancePaymentsWithFilter(this.filterDataValues).subscribe(res => {
      console.log('res ', res);
        this.totalAdvancePayments = res.total;
        this.sumCapital = res.aproveCapital;
        this.capitalPayment = res.capital;
      this.seatIndex = res.index;
      this.loading = false;
      if (res !== false && res !== null) {
        this.lenData = res.count;
        this.advancePaymentsData = res.items;
      }
    }, error => {
        console.error('error al obtener los anticipos: ', error);
        this.loading = false;
        // this.alertMessage = 'No se encontraron registros';
      }
    );
  }

  getSumToHarvest(harvest: string) {
    this._advancePaymentService.getTotalAdvancePaymentPerHarvest(harvest).subscribe(response => {
        this.lenData = response.len;
        this.totalAdvancePayments = response.sum;
        this.sumCapital = response.mount;
    }, err => console.error('Error al obtener suma de anticipos por cosecha: ', err));
  }

  getSumToHarvestAproccor(harvest: string) {
    this._advancePaymentService.getTotalAdvancePaymentPerHarvestAproccor(harvest).subscribe(response => {
      this.lenData = response.len;
      this.totalAdvancePayments = response.sum;
    }, err => console.error('Error al obtener suma de anticipos por cosecha: ', err));
  }

  changePage(paginator: MatPaginator): void {
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTableAdvancePayment');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getAdvancePaymentsWithFilters();
  }

  openDialogAdd(): void {
    const dialogRef = this.dialog.open(AdvancePaymentAddComponent, {
      panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
      width: '700px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        this.getAdvancePaymentsWithFilters();
      }
    });
  }

  openAdvancePaymentEdit(paymentObj: AdvancePayment): void {
    this._paymentService.checkExistAdvancePayment(paymentObj._id).subscribe(response => {
      const obj = _.cloneDeep(paymentObj);
      obj.coffeefarmer = obj.coffeefarmer._id;
      if (response === null) {
        this.alertMessage = '';
        const dialogRef = this.dialog.open(AdvancePaymentEditComponent, {
          width: '720px',
          panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
          data: obj
        });
        dialogRef.afterClosed().subscribe(callback => {
          if (callback !== 'cancel') {
            this.getAdvancePaymentsWithFilters();
          }
        });
      } else {
        this.alertMessage = `El anticipo #${paymentObj.cod} no puede editarse, ya tiene abonos registrados.`;
        setTimeout(() => this.alertMessage = '', 5000);
      }
    }, err => console.error('Error al buscar anticipo existente: ', err));
  }

  openPayDialog(ap: AdvancePayment): void {
    const company = this.identi.company._id;
    // let modalRef: any = AdvancePaymentsPayComponent; // * Abono con recalculo.
    let modalRef: any = PaymentAddV1Component;
/*     if (company === '5f50fdc413c3ab1f4454c17d') { // * Abono a saldo total con recalculo
      modalRef = AdvancePaymentPayBalanceComponent;
    } */

    if (ap.payment_status !== 'Pagado') {
      const dialogRef = this.dialog.open(modalRef , {
        width: '620px',
        panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
        data: ap
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result !== 'cancel') {
          this.getAdvancePaymentsWithFilters();
          /* setTimeout(() => {
            this.printPayment(result);
          }, 1000); */
        }
      });
    } else {
      this.alertMessage = `Abono #${ap.cod} ya ha sido pagado completamente.`;
      setTimeout(() => this.alertMessage = '', 5000);
    }
  }

  openDialogDelete(advancePayment: AdvancePayment): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular anticipo',
        title: '',
        text: `¿Seguro que quieres anular el anticipo <strong>#${advancePayment.cod}</strong>?`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        advancePayment.enabled = false;
        this._advancePaymentService.editAdvancePayment(advancePayment._id, advancePayment).subscribe(() => {
          console.log('Anticipo anulado');
          this._paymentService.getPaymentsByAP(advancePayment._id).subscribe(res => {
            const paymentsToCancel = res as Payment[];
            paymentsToCancel.forEach(current => {
              current.enabled = false;
              this._paymentService.editPayment(current._id, current).subscribe(() => {
                console.log('Abono anulado');
              }, err => console.error('Error al anular abono: ', err));
            });
          }, err => console.error('Error al obtener abonos a anular: ', err));
          this.getAdvancePaymentsWithFilters();
        }, error => console.error('Error al anular anticipo: ', error));
      }
    });
  }

  openDialogSeat(advancePayment: AdvancePayment): void {
    if (!this.seatIndex.hasOwnProperty(advancePayment._id)) {

      advancePayment['tag'] = 'Anticipo';
      const dialogRef = this.dialog.open(SeatAddModalComponent, {
        height: '700px',
        width: '900px',
        panelClass: ['animate__animated', 'animate__zoomIn', 'outline_modal'],
        data: advancePayment
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getAdvancePaymentsWithFilters();
        }
      });
    } else {
      this.alertMessage = `El anticipo ${advancePayment.cod} ya ha sido contabilizado`;
      setTimeout(() => this.alertMessage = '', 6000);
    }
  }

  openDialogDetails(advancePayment: AdvancePayment): void {
    this._paymentService.getPaymentsByAP(advancePayment._id).subscribe(response => {
      this.detailObj.payments = response as Payment[];
      this.detailObj.ap = advancePayment;
    }, err => console.error('Error al obtener abonos de anticipo: ', err));
  }

  // ? ------------------ Filter functions end --------------------

  printVoucher(ap, status): void {
    this._printService.getAdvancePayment(ap, true);
  }

  printPayment(payment: Payment) {
    !payment.fullbalance ?
      this._printService.getPayment(payment._id) :
      this._printService.getPaymentBalance(payment.fullbalanceId, payment.coffeefarmer);
  }

  printPromissoryNote(ap): void {
    console.log('print promissori', ap);
    if (this.identi.company._id === '5f50fdc413c3ab1f4454c17d') {
      console.log('1');
      this._printService.getPromissoryNote(ap);
    } else {
      console.log('2');
      this._printService.getAdvancePayment(ap, false);
    }
    /* .then(
        (res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
        .catch(err => console.log('Error', err)); */
  }

  printPromissoryNoteAruco(ap): void {
    this._printService.getPromissoryNoteAruco(ap)
    .then(
        (res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
        .catch(err => console.log('Error', err));
  }

    printPromissoryNoteAPROCASCO(ap): void {
    this._printService.getPromissoryNoteAPROCASCO(ap)
    .then(
        (res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
        .catch(err => console.log('Error', err));
  }

    printPromissoryNoteAprocaf(ap): void {
    this._printService.getPromissoryNoteAprocaf(ap)
    .then(
        (res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
        .catch(err => console.log('Error', err));
  }

  exportAsXLSX(): void {
    this.filterDataValues['skip'] = 0;
    this.filterDataValues['limit'] = 10000;
    this.filterDataValues.enabled = true;
    this._advancePaymentService.getAdvancePaymentsWithFilter(this.filterDataValues).subscribe(
      res => {
        if (!res) {
        } else {
          this.convertToExcel = res.items as AdvancePayment[];
          this.convertToExcel.forEach((element, i) => {
            const newDate = this.pipe.transform(element.date, 'shortDate');
            if (element === undefined) {
              console.error('Undefined element: ', element, i);
            }

              const name = element.hasOwnProperty('coffeefarmer') ?
                ( element.coffeefarmer !== null ? element.coffeefarmer.name : '') : '';
              const porcent = element.i_porcent * 100;
              const rows = {
                Código: element.cod,
                Fecha: newDate,
                Productor: name,
                Días: element.days,
                Monto: element.mount.toFixed(2),
                Porcentaje: porcent.toFixed(2) + '%',
                Interes: element.total_interest.toFixed(2),
                Saldo: element.total_balance.toFixed(2),
                Observaciones: element.observations,
                Cosecha: element.harvest,
                Estado: element.payment_status,
                currentCapital: element.current_capital,
              };

              const convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
              this.convertToExcel = convertToExcel;

          });
        }
        this.excelService.exportAsExcelFile(this.convertToExcel, 'Anticipos');
      },
      error => {
          console.error('err', error);
      }
    );
  }

  // -------------------------- ! Importar un documento de excel con columnas predefinidas, -----------------------
  // * RECALCULO DE INTERESES LOCAL Y A BASE DE DATOS

  recalculate(advancePayment: AdvancePayment) {
    console.log('----------------- Recalculando local "manualmente" -----------------');
    this._advancePaymentService.recalculateAdvancePayment(advancePayment._id, false);
  }

  recalculateDB(advancePayment: AdvancePayment) {
    console.log('----------------- Recalculando DB "manualmente" -----------------');
    this._advancePaymentService.recalculateAdvancePayment(advancePayment._id, true);
  }

  recalculateAll(changes = true) {
    console.log('----------------- Recalculando TODOS -----------------');
    this._testingService.paidAdvancePaymentsValues(changes);
  }

}
