import { MatPaginator } from '@angular/material';
import { UserService } from '../../../../../services/user.service';
import { CoffeeFarmer } from '../../../../../models/productor';
import { CollectionCenter } from '../../../../../models/collectionCenter';
import { CollectionCenterService } from '../../../../../services/collectionCenter.service';
import { ProductorService } from '../../../../../services/productor.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-advance-payments-filter',
  templateUrl: './advance-payments-filter.component.html',
  styleUrls: ['./advance-payments-filter.component.css']
})
export class AdvancePaymentsFilterComponent implements OnInit {
  public form: FormGroup;
  public status = ['Pendiente', 'Parcial', 'Pagado'];
  public harvests = ['Todas', '2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025'];
  public availableCoffeefarmers: CoffeeFarmer[];
  public filteredCoffeefarmers: CoffeeFarmer[];
  public availablecashCounts: CollectionCenter[];
  public filteredcashCounts: CollectionCenter[];
  public currentHarvest: string;
  public company: any;

  @Output() dataToFilter: EventEmitter<any>;
  @Input() filterDataValues = {};
  @Input() page = 0;
  @Input() paginator: MatPaginator;
  constructor(
    private _cashCountService: CollectionCenterService,
    private coffeefarmerService: ProductorService,
    private _userService: UserService,
    private fb: FormBuilder,
  ) {
    this.dataToFilter = new EventEmitter();
    this.currentHarvest = this._userService.getCompany().harvest;
    if (!this.harvests.some(h => h === this.currentHarvest)) {
      this.harvests.push(this.currentHarvest);
    }
    this.company = this._userService.getIdentity().company;
    console.log('filterData" ', this.filterDataValues);
  }

  ngOnInit() {
    this.createForm();
    this.listeners();
    this.getCoffeefarmers();
    this.getCashCounts();
    this.form.get('harvest').setValue(this.currentHarvest);
  }

  getCoffeefarmers(): void {
    this.coffeefarmerService.getProductores().subscribe(response => {
      this.availableCoffeefarmers = response as CoffeeFarmer[];
      this.filteredCoffeefarmers = response as CoffeeFarmer[];
    });
  }

  getCashCounts(): void {
    this._cashCountService.getCollectionCenters().subscribe(res => {
      this.availablecashCounts = res as CollectionCenter[];
      this.filteredcashCounts = res as CollectionCenter[];
    }, err => console.error('Error al obtener agencia', err));
  }

  listeners(): void {
    this.form.get('coffeefarmerSearch').valueChanges.subscribe((value: string) => {
      this.filteredCoffeefarmers = this.availableCoffeefarmers
        .filter(coffeefarmer => String(coffeefarmer.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('collectionCenterS').valueChanges.subscribe((value: string) => {
      this.filteredcashCounts = this.availablecashCounts
        .filter(cash => String(cash.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });
  }

  clearForm(): void {
    // tslint:disable-next-line:forin
    for (const name in this.form.controls) {
      if (name !== 'harvest') {
        this.form.controls[name].setValue('');
      }
    }
    this.form.get('harvest').setValue(this.currentHarvest);
  }

  checkFilterData(): any {
    const filters = JSON.parse(JSON.stringify(this.form.getRawValue()));
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '' || (key === 'harvest' && filters[key] === 'Todas')) {
        delete filters[key];
      }
    });

    console.log('filters : ', filters);
    return filters;
  }

  getFilterData(): void {
    const data = this.checkFilterData();

    // * Si es coadecal
    if (this.company._id === '5f50fdc413c3ab1f4454c17d') {
      console.log('coadecal');
      data.enabled = true;
    }
    this.page = 0;
    this.paginator.firstPage();
    this.dataToFilter.emit(data);
  }

  clearField(event: any, field?: string): void {
    event.stopPropagation();
    this.form.get(field).setValue('');
  }

  createForm(): void {
    this.form = this.fb.group({
      cod:                [''],
      harvest:            [''],
      coffeefarmer:       [''],
      coffeefarmerSearch: [''],
      collectionCenter: [''],
      collectionCenterS: [''],
      coffeefarmerName:   [''],
      text:               [''],
      date_in:            [''],
      date_out:           [''],
      payment_status:     [['Pendiente', 'Parcial']],
      observations:       [''],
    });
  }

}
