import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatPaginator } from '@angular/material';
import * as _ from 'lodash';

import { SidebarManagerService } from '../../../services/sidebar-manager.service';
import { ProductionService } from './../../../services/production.service';
import { InventoryService } from '../../../services/inventory.service';
import { PrintService } from '../../../services/print.service';
import { ExcelService } from '../../../services/excel.service';
import { DriedService } from '../../../services/dried.service';
import { UserService } from '../../../services/user.service';

import { ProductionAddComponent } from '../../production/productions/production-add/production-add.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

import { AppSidebar } from '../../../models/sidebar';
import { Dried, ProductionControl } from '../../../models/dried';
import { Inventory } from '../../../models/inventory';
import { PaginatorConfig } from '../../../models/miscellaneous';
import { PhysicalAnalysis } from './../../../models/physical';
import { Production } from './../../../models/production';
import { Sample } from '../../../models/sample';
import { SensoryAnalysis } from './../../../models/sensory';
import { prodConstraint } from '../../../helpers/partialControl';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css'],
  providers: [UserService, ExcelService, InventoryService, DatePipe, DriedService, PrintService]
})
export class InventoryComponent implements OnInit {

  public convertToExcel = [];
  public pipe = new DatePipe('es-HN');
  public summaryQQData = {
    dry: 0,
    wet: 0,
    performance: 0
  };
  public paginatorConfig: PaginatorConfig = {
    count: 0,
    page: 0,
    limit: 100,
    label: 'Inventario'
  };
  public sidebars: AppSidebar[] = [
    { name: 'sample-add', title: 'Agregar muestra', layout: {
      width: 25, height: 'auto'
    }},
  ];
  public dryInventory: Dried[] = [];
  public select = false;
  public indexSelected: any = {};
  public status = ['Catado', 'Producción', 'Mezcla'];
  public harvestList: string[] = ['2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025'];
  public form: FormGroup;
  public identi;

  constructor(
    private _userService: UserService,
    private _driedService: DriedService,
    private _printService: PrintService,
    private excelService: ExcelService,
    private _productionService: ProductionService,
    public _sidebarManager: SidebarManagerService,
    private _fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.identi = this._userService.getIdentity();
  }

  ngOnInit() {
    this._sidebarManager.setSidebarsList(this.sidebars);
    this.getDryInventory();
    this.getSummaryData();
    this.createForm();
    const currentHarvest = this.identi.company.harvest;
    this.form.get('harvest').setValue(currentHarvest);
  }

  getDryInventory() {
    const params = {
      limit: this.paginatorConfig.limit,
      skip: this.paginatorConfig.page * this.paginatorConfig.limit,
      harvest: this._userService.getCompany().harvest
    };
    this._driedService.getDryInventoryWithFilters(params).subscribe(res => {
      this.dryInventory = res.items;
      this.paginatorConfig.count = res.count;
      console.log('inventario seco', res.items);
    }, err => console.error('Error al obtener inventario seco con filtros: ', err));
  }

  getSummaryData(): void {
    this._driedService.getSummaryDry().subscribe(res => {
      this.summaryQQData.dry = res ? res.dry : 0;
      this.summaryQQData.wet = res ? res.wet : 0;
      this.summaryQQData.performance = res ? res.performance : 0;
    });
  }

  selectDry(dry: Dried): void {
    const control: ProductionControl = dry.inventoryStatus.production;
    const available = control.ref.length === 0 ? dry.dry_qq : control.remaining;
    if (this.indexSelected.hasOwnProperty(dry._id)) {
      delete this.indexSelected[dry._id];
    } else {
      this.indexSelected[dry._id] = {
        available,
        prod: available,
        microlot: dry.microlot,
        blend: dry.inventoryStatus.blend
      };
    }
  }

  openAddSample = (dried: Dried): void => {
    this._sidebarManager.open('sample-add', {
      dried
    });
    this._sidebarManager.afterClosed('sample-add').subscribe((results) => {
      if (results) this.getDryInventory();
    });
  }

  changePage(paginator: MatPaginator) {
    this.paginatorConfig.page = paginator.pageIndex;
    // window.document.getElementById('matTableSettlement').scrollTo(0, 0);
    this.getDryInventory();
  }

  openDialogCreateProduction(): void {
    const index = this.indexSelected;
    const mit: any = Object.values(index);
    console.log(mit[0], index, 'el microlot');
    const dialogRef = this.dialog.open(ProductionAddComponent, {
      panelClass: ['animate__animated'],
      width: '25rem',
      data: _.sumBy(Object.values(index), 'prod')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const production: Production = result;
        production.dry_qq = Object.values(index).map((curr: any) => curr.prod);
        production.rest_qq = Object.values(index).map((curr: any) =>
          parseFloat(Number(Number(curr.available) - Number(curr.prod)).toFixed(6))),
        production.dry_inventory = Object.keys(this.indexSelected);
        production.microlot = mit[0].microlot;
        if (mit[0].blend) {
          production.blend = mit[0].blend._id;
        }
        console.log(production, mit);
        this._productionService.editProduction(production._id, production).subscribe(updated => {
          console.log('Produccion actualizada: ', updated);
        }, (err: HttpErrorResponse) => console.error('Error al actualizar produccion: ', err));

        Object.keys(index).forEach((id, i) => {
          const current: Selected = index[id];
          this._driedService.getDried(id).subscribe((dried: Dried) => {
            const prod: ProductionControl = dried.inventoryStatus.production;
            if (prod.ref.length === 0) {
              prod.total = dried.dry_qq;      // * Solo aqui se cambia este campo.
              prod.produced = 0;              // * Cantidad producida inicialmente.
              prod.remaining = dried.dry_qq;  // * Cantidad pendiente por producir, el total secado.
              prod.finished = false;
              prod.partial = false;
            }
            prod.produced += current.prod;
            prod.remaining -= current.prod;
            prod.ref.push(production._id);
            prodConstraint(prod);
            this._driedService.editDried(dried._id, {
              inventoryStatus: Object.assign(dried.inventoryStatus, { production: prod })
            }).subscribe(response => {
              console.log('Inventario seco actualizado: ', response);
              if (i === Object.keys(index).length - 1) {
                this.getDryInventory();
                this.indexSelected = {};
              }
            }, (err: HttpErrorResponse) => console.error('Error al actualizar inventario seco: ', err));
          });
        });
      }
    });

  }

  openDialogD(inventory: Inventory) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar registro',
        title: '',
        text: `¿Seguro que quieres eliminar el registro <strong#>${inventory.cod}</strong>?`,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteInventory(inventory._id);
      }
    });
  }

  deleteInventory(id) {
    this._driedService.deleteDried(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.getDryInventory();
        }
      },
      error => {
        const errorMessage = <any>error;

        if (errorMessage != null) {
          const body = JSON.parse(error._body);
          console.log(error);
        }
      }
    );
  }

  imprimir(inventory) {
    this._printService.printInventory(inventory._id).then(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }

  exportAsXLSX(): void {
    const params = {
      limit: 25000,
      skip: 0,
      harvest: '2023 / 2024'
    };
    console.log('params to export excel: ', params);
    this._driedService.getDryInventoryWithFilters(params).subscribe(res => {
      this.convertToExcel = res.items;
      this.convertToExcel.forEach((element, i) => {
        const newDate = this.pipe.transform(element.date, 'shortDate');
        const newDateF = this.pipe.transform(element.date_end, 'shortDate');
        if (element === undefined) {
          console.error('Undefined element: ', element, i);
        }
          const name = element.hasOwnProperty('warehouse') ? ( element.warehouse !== null ? element.warehouse.name : '') : '';
          const machine = element.hasOwnProperty('machine') ? ( element.machine !== null ? element.machine.name : '') : '';
          const observations = element.hasOwnProperty('observations') ? ( element.observations !== null ? element.observations : '') : '';
          const wet_quintals = element.hasOwnProperty('wet_quintals') ? ( element.wet_quintals !== null ? element.wet_quintals : 0) : 0;
          const dry_qq = element.hasOwnProperty('dry_qq') ? ( element.dry_qq !== null ? element.dry_qq : 0) : 0;
          const r_qq = element.hasOwnProperty('r_qq') ? ( element.r_qq !== null ? element.r_qq : 0) : 0;
          let microlot = '';
          let farmer = '';
          let notes = [];
          if ('weight_notes' in element) {
            if (element.weight_notes.length > 0) {
              microlot = !element.weight_notes[0].microlot ? '' : 'Microlote';
              notes = element.weight_notes.map(x => x.cod);
              farmer = !element.weight_notes[0].coffeefarmer.name ? '' : element.weight_notes[0].coffeefarmer.name;
            }
          }

          const rows = {
            Código: element.cod,
            Fecha: newDate,
            Fecha_Final: newDateF,
            Bodega: name,
            Microlote: microlot,
            Productor: farmer,
            Notas_de_Peso: notes.toString(),
            Días: element.days,
            Horas: element.hours,
            Quintales_Húmedos: wet_quintals.toFixed(2),
            Quintales_Secos: dry_qq.toFixed(2),
            Rendimiento: r_qq.toFixed(2),
            Cosecha: element.harvest,
            observaciones: observations,
          };

          const convertToExcel = Object.assign([], this.convertToExcel, { [i]: rows });
          this.convertToExcel = convertToExcel;

      });
      this.excelService.exportAsExcelFile(this.convertToExcel, 'Inventario');
    }, err => console.error('Error al obtener inventario seco con filtros: ', err));
  }

  filterData = (): void => {
    const filters = Object.assign(this.form.getRawValue(), {
      limit: this.paginatorConfig.limit,
      skip: this.paginatorConfig.page * this.paginatorConfig.limit,
      harvest: this._userService.getCompany().harvest
    });
    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || filters[key] === '' || (key === 'harvest' && filters[key] === 'Todas')) {
        delete filters[key];
      }
    });
    console.log('filters', filters);
    this._driedService.getDryInventoryWithFilters(filters).subscribe(({ count, items }) => {
      if (filters.status === 'Catado') {
        this.dryInventory = items.filter((current: Dried) => current.inventoryStatus.samples.length > 0);
      } else if (filters.status === 'Producción') {
        this.dryInventory = items.filter((current: Dried) => current.inventoryStatus.production.ref.length > 0);
      } else if (filters.status === 'Mezcla') {
        this.dryInventory = items.filter((current: Dried) => current.inventoryStatus.blend);
      } else {
        this.dryInventory = items;
      }
      this.paginatorConfig.count = count;
    }, (err: HttpErrorResponse) => console.error('Error al obtener inventario seco', err));
  }

  createForm = (): void => {
    this.form = this._fb.group({
      cod:      [''],
      status:   [''],
      harvest:  [''],
    });
  }

  getSensoryScore = (samples: Sample[]): string | number => {
    const sample: Sample = samples[samples.length - 1];
    const sensory: SensoryAnalysis = sample.analysis.sensory;
    if (!sensory) return 'NA';
    const sampleIndex = sensory.results.findIndex((result) => result.sample === sample._id);
    const score = sensory.results[sampleIndex];
    return score.puntuation;
  }

  getPhysicalScore = (samples: Sample[]): string | number => {
    const sample: Sample = samples[samples.length - 1];
    const physical: PhysicalAnalysis = sample.analysis.physical;
    if (!physical) return 'NA';
    const sampleIndex = physical.results.findIndex((result) => result.sample === sample._id);
    const score = physical.results[sampleIndex];
    return score.values.threshingPerformance;
  }

  verifyProd = (value, dry: Dried): void => {
    const control: ProductionControl = dry.inventoryStatus.production;
    const remaining = control.ref.length === 0 ? dry.dry_qq : control.remaining;
    if (value > remaining) {
      this.indexSelected[dry._id].prod = remaining;
    }
    console.log('blur prod', value, control);
  }

  sumBy(object: any, field: string): number {
    return Object.keys(object).reduce((acc, key) => acc + object[key][field] , 0);
  }

  mapBy(object: any, field: string): any {
    return Object.keys(object).map(key => object[key][field]);
  }

}

interface Selected {
  prod: number; // * Valor a mandar a produccion.
  available: number; // * Valor disponible para producción.
}
