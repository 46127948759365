import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

import { AsisstanceService } from '../../../services/asisstance.service';
import { PrintService } from '../../../services/print.service';
import { UserService } from '../../../services/user.service';
import { GLOBAL } from '../../../services/global';

import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';

import { Asisstance } from '../../../models/asisstance';

@Component({
  selector: 'app-asissts',
  templateUrl: './asissts.component.html',
  styleUrls: ['./asissts.component.css']
})
export class AsisstsComponent implements OnInit {



  public title = 'Lista de Asistencia';
  public inventory: any = {};
  public identity;
  public identi;
  public harvests = ['Todas', '2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025'];
  public currentHarvest = '';
  public indexedAsisstances: any = {};
  public loading = false;
  public dataTable;
  public form: FormGroup;
  public moreBtn = false;
  public alertMessage = '';
  public exist = 0;
  public totalAsisstances = 0;
  public asisstancesData: Asisstance[] = [];
  public convertToExcel = [];
  public countAsisstances = 0;
  public repetidos = [];
  public pipe = new DatePipe('es-HN');
  public status = ['Pendiente', 'Parcial', 'Pagado'];
  public displayedColumns: string[] = ['cod', 'topic', 'address', 'coffeefarmers', 'event_type', 'date', 'action' ];

  private tableRef: HTMLElement;
  private page = 0;
  private limit = 100;
  public currency;
  public tempData = {
    totalqq: 0,
    totalqqWn: 0,
    real: 0
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _userService: UserService,
    private _asisstanceService: AsisstanceService,
    private _printService: PrintService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.identity = this._userService.getCompany();
    this.identi = this._userService.getIdentity();
  }

  ngOnInit() {
    this.tableRef = document.getElementById('matTableAsisstance');
    this.currentHarvest = this.identi.company.harvest;
    this.currency = GLOBAL.currency;
    if (!this.harvests.some(h => h === this.currentHarvest)) {
      this.harvests.push(this.currentHarvest);
    }
    this.getDataWithFilters();
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
  }

  getDataWithFilters(): void {
    this._asisstanceService.getAsisstances().subscribe(res => {
      this.loading = false;
        this.asisstancesData = res;
        this.countAsisstances = res.length;
    }, err => {
      console.error('ERROR EN Liquidaciones con filtro ', err);
      this.loading = false;
      this.alertMessage = 'No se pudieron obtener los registros';
    });
  }

  filterData(): void {
    this.page = 0;
    this.paginator.firstPage();
    this.getDataWithFilters();
  }

  changePage(paginator: MatPaginator) {
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTableAsisstance');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getDataWithFilters();
  }


  openDialogD(asisstance) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular Remision',
        title: '',
        text: `¿Seguro que quieres anular <strong>#${asisstance.seq}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.cancelAsisstance(asisstance);
      }
    });
  }

  print(sale: Asisstance): void {
    console.log('Sale to print: ', sale);
    this._printService.getAsisstance(sale._id).then((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    })
    .catch(err => console.log('Error', err));
  }

  cancelAsisstance(asisstance) {
    asisstance['enabled'] = false;
    this._asisstanceService.update(asisstance._id, asisstance).subscribe(response => {
      console.log('Remision anulada: ', response);
      setTimeout(() => {
        this.getDataWithFilters();
      }, 1000);
      // todo : Anular asiento
    }, err => console.error('Error al anular Remision: ', err));
  }

}
