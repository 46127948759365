import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSelect, MatOption } from '@angular/material';
import { DatePipe } from '@angular/common';

import { UserService } from '../../services/user.service';
import { CollectionCenterService } from '../../services/collectionCenter.service';
import { ProductorService } from '../../services/productor.service';
import { OriginService } from '../../services/origin.service';
import { ReportService } from '../../services/reports.service';
import { WarehouseService } from '../../services/warehouse.service';
import { ExporterService } from '../../services/exporter.service';
import { ExcelService } from '../../services/excel.service';
import { CertificationService } from '../../services/certification.service';

import { Warehouse } from '../../models/warehouse';
import { User } from '../../models/user';
import { CollectionCenter } from '../../models/collectionCenter';
import { Exporter } from '../../models/exporter';
import { AdvancePaymentService } from '../../services/advancePayment.service';
import { PaymentService } from '../../services/payment.service';
import { DriedService } from '../../services/dried.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [UserService, ReportService, ExporterService, CollectionCenterService, ProductorService, OriginService, WarehouseService, ExcelService, CertificationService]
})
export class ReportsComponent implements OnInit {
  public report: string;
  public alertMessage = '';

  public availableCoffeeFarmer = [];
  public availableUser = [];
  public availableOrigin = [];
  public availableX = [];
  public availableWarehouses = [];
  public availableCertification = [];
  public availableCollectionCenter = [];
  public filteredOptions = [];
  public filteredOptionsCF = [];
  public filteredOptionsX = [];
  public filteredOptionsOrigin = [];
  public filteredWarehouses = [];
  public filteredCollectionCenter = [];
  public filteredUser = [];
  public filteredCertifications = [];
  public form: FormGroup;
  public allSelected = false;
  public harvests = ['2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025', 'Todas'];
  public driedStatus = ['Todas', 'Proceso', 'Secando', 'Descargando', 'Finalizado'];
  public wnStates = ['Seco', 'Uva', 'Mojado', 'Humedo', 'Resaca', 'StockLot', 'Oro Exportable'];
  public wnLiq = ['Liquidado', 'No liquidado', 'Todos'];
  public wnReceptions = ['Uva', 'Oro', 'Pergamino'];
  public cFOptions = [{text: 'Elegir Productor', value: false}, {text: 'Todos', value: true}];
  public optionsSelect = [
    // * ---------------------------------------- Anticipos ----------------------------------------
    { value: 'advance_payments', text: 'Anticipos', path: 'advance-payments'},
    { value: 'advance_payments_per', text: 'Anticipos por Productor', path: 'advance-payments-by-coffeefarmer', producer: true},
    { value: 'advance_payments_user', text: 'Anticipos por Centro de Acopio', path: 'advance-payments-by-user', center: true},
    { value: 'payment_coffeefarmer', text: 'Abonos por Productor', path: 'payments-by-coffeefarmer', producer: true},

    // * -------------------------------------- Liquidaciones --------------------------------
    { value: 'settlement', text: 'Liquidaciones', path: 'settlements'},
    { value: 'liq_coffeefarmer', text: 'Liquidaciones por Productor', path: 'settlements-by-coffeefarmers', producer: true},
    { value: 'liq_user', text: 'Liquidaciones por Centro de Acopio', path: 'settlements-by-user', center: true},

    { value: 'weight_notes_by_coffeefarmer', text: 'Notas de Peso por Productor', path: 'weight-notes-by-coffeefarmer', producer: true},
    { value: 'cafeDeposito', text: 'Depósito y Propio', path: 'weight-notes-by-entry'},
    { value: 'dpp', text: 'Depósito y Propio por Productor', path: 'weight-notes-by-entry-coffeefarmer', producer: true},
    { value: 'dpcc', text: 'Depósito y Propio por Centro de Acopio', path: 'weight-notes-by-entry-collection-center', center: true},
    // { value: 'weight_notes_producer', text: 'Notas de Peso por Productor', path: 'weight_notes_producer', producer: true},
    { value: 'weight_notes_warehouse', text: 'Notas de Peso por Bodega', path: 'weight-notes-by-warehouse', warehouse: true},
    { value: 'entryOrigin', text: 'Notas de Peso por Procedencia', path: 'weight-notes-by-origin'}, //
    { value: 'weight_notes_certs', text: 'Notas de peso por Certificaciones', path: 'weight-notes-by-certifications', certs: true},
    // tslint:disable-next-line:max-line-length
    { value: 'weight_notes_states', text: 'Notas de peso por Estado', path: 'weight-notes-by-warehouse-state', state: true, warehouse: true},
    { value: 'uva', text: 'Notas de Peso por Recepcion', path: 'weight-notes-by-reception', reception: true},
    { value: 'microlots', text: 'Microlotes Ingresados', path: 'microlots'},

    { value: 'inventory', text: 'Ordenes de Secados', path: 'drieds', status: true},

    { value: 'daily_position', text: 'Posicion Diaria de Productor', path: 'daily-position'},
    { value: 'account-status', text: 'Estado de Cuenta de  Productor', path: 'account-status'},

    { value: 'ihcafe-vouchers', text: 'Comprobantes de IHCAFE', path: 'ihcafe-vouchers'},
    { value: 'referral_exporter', text: 'Remisiones por Exportador', path: 'referral-by-exporter', exporter: true},
    { value: 'ccc', text: 'Depósitos por Centro de Acopio', path: 'cash-by-collection-center', center: true},
];
  public pipe = new DatePipe('es-HN');
  @ViewChild('certsSelect') skillSel: MatSelect;

  public users = [];
  public collectionCenters = [];
   public exporters = [];

  constructor(
    private _reportService: ReportService,
    private _productorService: ProductorService,
    private _originService: OriginService,
    private _warehouseService: WarehouseService,
    private _certificationService: CertificationService,
    private _userService: UserService,
    private excelService: ExcelService,
    private _collectionCenterService: CollectionCenterService,
    private _exporterService: ExporterService,
    private advancePaymentService: AdvancePaymentService,
    private paymentService: PaymentService,
    private driedService: DriedService,
    public dialog: MatDialog,
    public fb: FormBuilder,
  ) {
    const company = this._userService.getCompany()._id;
    if (company === '5f80c3091052d10488531338' || company === '5d7742116bc1d64060abfc9b') {
      this.optionsSelect.unshift({
        value: 'settlements_contributions', text: 'Liquidaciones por contribuciones', path: 'settlements-by-contributions'
      });
    }
  }

  ngOnInit() {
    this.createForm();
    this.getDataFromServices();
    this.listenersOfInputs();
  }

  getDataFromServices(): void {
    this._productorService.getProductores().subscribe(producers => {
      this.availableCoffeeFarmer = producers;
      this.filteredOptionsCF = producers;
    }, err => console.error('Error al obtener productores', err));

    this._exporterService.getExporters().subscribe(exporters => {
      this.availableX = exporters;
      this.filteredOptionsX = exporters;
    }, err => console.error('Error al obtener productores', err));

    this._originService.getOrigins().subscribe(origin => {
      this.availableOrigin = origin;
      this.filteredOptionsOrigin = origin;
    }, err => console.error('Error al obtener origenes', err));

    this._warehouseService.getWarehouses().subscribe(warehouses => {
      this.filteredWarehouses = warehouses as Warehouse[];
      this.availableWarehouses = warehouses as Warehouse[];
    }, err => console.error('Error al obtener bodegas', err));

    this._certificationService.getCertifications().subscribe(certs => {
      this.filteredCertifications = certs as Warehouse[];
      this.availableCertification = certs as Warehouse[];
      console.log('certs: ', certs);
    }, err => console.error('Error al obtener bodegas', err));

    this._userService.getUsers().subscribe((response: User[]) => {
      this.users = response;
      this.availableUser = response;
      this.filteredUser = response;
    }, err => console.error('Error al obtener usuarios: ', err));
    
    this._collectionCenterService.getCollectionCenters().subscribe((response: CollectionCenter[]) => {
      this.collectionCenters = response;
      this.availableCollectionCenter = response;
      this.filteredCollectionCenter = response;
    }, err => console.error('Error al obtener Centros de Acopio: ', err));
  }

  listenersOfInputs(): void {
    this.form.get('coffeefarmerSearch').valueChanges.subscribe((value: string) => {
      this.filteredOptionsCF = this.availableCoffeeFarmer
        .filter(productor =>
          String(productor.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('exporterSearch').valueChanges.subscribe((value: string) => {
      this.filteredOptionsX = this.availableX
        .filter(exporter =>
          String(exporter.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('userSearch').valueChanges.subscribe((value: string) => {
      this.filteredUser = this.availableUser
        .filter(user =>
          String(user.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('collectionCenterSearch').valueChanges.subscribe((value: string) => {
      this.filteredCollectionCenter = this.availableCollectionCenter
        .filter(center =>
          String(center.name).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .indexOf(value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1);
    });

    this.form.get('warehouseName').valueChanges.subscribe(value => {
      this.filteredWarehouses = this.availableWarehouses
        .filter(productor => productor.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
    });

    this.form.get('originName').valueChanges.subscribe(value => {
      this.filteredOptionsOrigin = this.availableOrigin
        .filter(origin => origin.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
    });

    this.form.get('certName').valueChanges.subscribe(value => {
      this.filteredCertifications = this.availableCertification
        .filter(cert => cert.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
    });
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;  // to control select-unselect
    if (this.allSelected) {
      this.skillSel.options.forEach( (item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach( (item: MatOption) => {item.deselect(); });
    }
  }

  createForm(): void {
    this.form = this.fb.group({
      coffeefarmer:           [''],
      coffeefarmerName:       [''],
      user:                   [''],
      userName:               [''],
      warehouse:              [''],
      warehouseName:          [''],
      collectionCenter:       [''],
      collectionCenterName:   [''],
            exporterCenter:       [''],
            exporter:       [''],
      exporterCenterName:   [''],
      certs:                  [[]],
      certName:               [''],
      date_in:                [''],
      date_out:               [''],
      path:                   [''],
      harvest:                [''],
      origin:                 [''],
      originName:             [''],
      reportName:             [''],
      value:                  [''],
      selected:               ['', Validators.required],
      status:                 ['Todas'],
      reception:              ['Uva'],
      liquidated:             ['Todos'],
      state:                  ['Seco'],
      file:                   ['pdf'],
      coffeefarmerSearch:     [''],
      userSearch:             [''],
      exporterSearch:             [''],
      collectionCenterSearch: [''],
      groupCoffeefarmer:      [false],
      groupUser:              [false],
      groupCollectionCenter:  [false],
      groupWarehouse:         [false],
      groupOrigin:            [false],
      groupExporter:          [false]
    });
  }

  print() {
    if (this.form.get('selected').value !== '') {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = this.report;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    } else {
      console.log('No hay seleccionado');
    }
  }

  onSubmit() {
    console.log(this.form.getRawValue());
    const parameters = this.checkParams(this.form.getRawValue());
    console.log('Parameters: ', parameters);
    this.alertMessage = '';
    if (this.form.get('file').value === 'xls') {
      parameters['excel'] = true;
      this._reportService.getReportPersonalizeExcel(parameters).subscribe(response => {
        this.exportToExcel(response[0].items);
      }, err => console.error('Error al obtener excel data: ', err));
    } else if (this.form.get('file').value === 'pdf') {
      this._reportService.getReportPersonalize(parameters).then((res: any) => {
        console.log('res!!: ', res);
        if (res !== null) {
          this.alertMessage = '';
          const file = new Blob([res], { type: 'application/pdf' });
          this.report = URL.createObjectURL(file);
        } else {
          this.alertMessage = `No se encontraron ${this.form.get('selected').value.text}`;
        }
      })
      .catch(err => console.log('Error', err));
    }
  }

  checkParams(parameters) {
    // parameters.certs = parameters.certs !== [] ? parameters.certs.filter(item => item !== 0) : [];
    parameters.certs = (parameters.certs && parameters.certs.length > 0) 
    ? parameters.certs.filter(item => item !== 0) 
    : [];
    Object.keys(parameters).forEach(key => {
      const current = parameters[key];
      if (key === 'status') {
        if (current === 0) { delete parameters['status']; }
      }
      if (current === undefined || current === null || current === '' || current === 'Todas' || current === 'Todos') {
        delete parameters[key];
      }
    });
    parameters['producer'] = parameters.selected['producer'];
    parameters['center'] = parameters.selected['center'];
    parameters['warehouse_id'] = parameters['warehouse'];
    parameters['warehouse'] = parameters.selected['warehouse'];
    parameters['user_id'] = parameters['user'];
    parameters['user'] = parameters.selected['user'];
    parameters['path'] = parameters.selected['path'];
    this.setNewTime(parameters);
    delete parameters['selected'];
    console.log('antes del c', parameters);
    return parameters;
  }

  setNewTime(parameters): void {
    if (parameters.hasOwnProperty('date_out')) {
      const date2 = new Date(parameters.date_out);
      date2.setDate(date2.getDate() + 1);
      date2.setSeconds(date2.getSeconds() - 1);
      parameters.date_out = date2;
    }
  }

  setCoffeefarmer(producer): void {
    this.form.get('coffeefarmer').setValue(producer._id);
    this.form.get('coffeefarmerName').setValue(producer.name);
  }

  setCollectionCenter(center): void {
    this.form.get('collectionCenter').setValue(center._id);
    this.form.get('collectionCenterName').setValue(center.name);
  }

  exportToExcel(data: any[]): void {
    console.log('arr export Excel: ', data);
    let convertToExcel = data;
    data.forEach((element, i) => {
      const newDate = this.pipe.transform(element.date, 'shortDate');
      if (element === undefined) {
        console.error('Undefined element: ', element, i);
      }
      const name = element.hasOwnProperty('coffeefarmer') ?
        ( element.coffeefarmer !== null ? element.coffeefarmer.name : '') : '';
      const rows = JSON.parse(JSON.stringify(element));
      delete rows.items;
      const convertToExcelTemp = Object.assign([], convertToExcel, { [i]: rows });
      convertToExcel = convertToExcelTemp;
    });
    console.log('ToExcelAnticipos: ', convertToExcel);
    this.excelService.exportAsExcelFile(convertToExcel, 'Reporte liquidaciones');
  }
}
