import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { UserService } from '../../../services/user.service';
import { PrintService } from '../../../services/print.service';
import { InventoryService } from '../../../services/inventory.service';
import { AdvancePaymentService } from '../../../services/advancePayment.service';
import { DriedService } from '../../../services/dried.service';
import { SettlementService } from '../../../services/settlement.service';
import { TransferService } from '../../../services/transfer.service';

import { Inventory } from '../../../models/inventory';
import { InventoryAddComponent } from '../inventory-add/inventory-add.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { ExcelService } from '../../../services/excel.service';
import { ImportDataService } from '../../../../app/services/import-data.service';
import { Company } from '../../../models/company';
import { AppSidebar } from '../../../models/sidebar';
import { SidebarManagerService } from '../../../services/sidebar-manager.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-weight-notes',
  templateUrl: './weight-notes.component.html',
  styleUrls: ['./weight-notes.component.css'],
  providers: [ ImportDataService ]
})
export class WeightNotesComponent implements OnInit, OnDestroy {
  public company: Company;
  public alertMessage;
  public harvests = ['2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025', '2025 / 2026'];
  public currentHarvest = '';
  public page = 0;
  public data: any[] = [];
  public user;
  public role;

  public inventory: any = {};
  public inventoriesScroll = new BehaviorSubject([]);
  public finished = false;
  public summative = { propio: 0, deposito: 0 };
  public filterDataValues: any = {};
  private selectedFile: File = null;
  private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

  public sidebars: AppSidebar[] = [
    { name: 'liquidation',        title: 'Liquidacion de nota de peso', layout: {
      width: 35, height: 'full'
    }},
    { name: 'print',        title: 'Impresion multiple', layout: {
      width: 25, height: 'full'
    }},
    { name: 'weight-note-add',    title: 'Agregar nota de peso', layout: {
      width: 40, height: 'full'
    }},
  ];
  public filters = false;

  constructor(
    private _inventoryService: InventoryService,
    private _userService: UserService,
    private _printService: PrintService,
    private _advancePaymentService: AdvancePaymentService,
    private _driedService: DriedService,
    private _settlementService: SettlementService,
    private _transferService: TransferService,
    private _importDataService: ImportDataService,
    public _sidebarManager: SidebarManagerService,
    private _router: Router,
    public dialog: MatDialog,
    private excelService: ExcelService,
  ) {
    this.company = this._userService.getCompany();
    this.user = this._userService.getIdentity();
    this.currentHarvest = this.company.harvest;
    this.inventory = {} as Inventory;
    this.filterDataValues['harvest'] = this.currentHarvest;
    this._sidebarManager.setSidebarsList(this.sidebars);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.unsubscribe();
  }

  ngOnInit() {
    console.log(this.user);
    this.role = this.user.userType.description;
    this.filterData(this.filterDataValues);
    this.getSumInventoryPerHarvest();

    /* this._inventoryService.getInventories().subscribe((res: Inventory[]) => {
      const range = res.filter(item => item.cod >= 230 && item.cod <= 241);
      console.log('Total list range: ', range.length, range);
      range.forEach(item => {
        item.dried = false;
        this._inventoryService.editInventory(item._id, item).subscribe(reponse => {
          console.log('Invnetario editado: ', reponse);
        }, err => console.error('Error al editar inventario: ', err));
      });
    }, err => console.error('Error al obtener inventario: ', err)); */
  }

  shouldShowContent(): boolean {
    const restrictedCompanies = ['5fa2a50e1c9a8952e6e1bc1c', '643d773bfebd7823442022cb'];

    if (!restrictedCompanies.includes(this.company._id)) {
      return true;
    }

    return this.role === 'ADMIN' && restrictedCompanies.includes(this.company._id);
  }

  todos() {
    this.data.forEach(id => {
      this.inventory.warehouse = '61b9008ce21ed916e98278ce';
      this._inventoryService.editInventory( id._id.$oid, this.inventory).subscribe(response => {
    }, error => console.error('Error: ', error));
    });
  }

    // tslint:disable-next-line:no-shadowed-variable
  filterData(data: any, reset = true): void {
    Object.keys(data).forEach(item => {
      const current = data[item];
      if (current === '' || current === null) {
        delete data[item];
      }
    });
    this.page = reset ? 0 : this.page;
    data['skip'] = this.page * 100;
    data['limit'] = 100;
    this.filterDataValues = Object.assign(this.filterDataValues, data);
    this._inventoryService.getDataWithFilters(data).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      // ! this.importData(response); NO USAR
      // ! this.importDataWarehouses(response);
      const currentData = _.cloneDeep(this.inventoriesScroll.getValue());
      this.inventoriesScroll = reset ? new BehaviorSubject([]) : this.inventoriesScroll;
      this.inventoriesScroll.next(_.concat(this.inventoriesScroll.getValue(), response));
    });
  }

  openDialogAdd() {
    const dialogRef = this.dialog.open(InventoryAddComponent, {
      width: '900px',
      height: '580px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.page = 0;
      this.inventoriesScroll = new BehaviorSubject([]);
      this.filterData(this.filterDataValues);
    });
  }

  openDialogD(inventory: Inventory) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Eliminar nota de peso',
        title: '',
        text: `¿Seguro que quieres eliminar <strong>#${inventory.cod}</strong> ?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') this.deleteInventory(inventory._id);
    });
  }

  // Imprimir     peso
  print(wn: Inventory) {
    if (this.company._id === '5de280c2ff21b207b052c7cc') {
      this._printService.getWeightNoteLast(wn._id).then((res: any) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          iframe.contentWindow.print();
        })
        .catch(err => console.log('Error', err));
    } else {
      console.log(wn._id);
      this._printService.getWeightNote(wn._id).then((res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');

        console.log(blob, res, blobUrl);
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
    }
  }

  printT(wn: Inventory) {
      this._printService.getWeightNoteT(wn._id).then((res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      })
      .catch(err => console.log('Error', err));
  }
  onScroll() {
    if (this.finished === false) {
        this.page += 1;
        this.filterData(this.filterDataValues, false);
      }
  }

  getSumInventoryPerHarvest(): void {
    this._inventoryService.getSumInventoryPerHarvest(this.currentHarvest)
    .pipe(takeUntil(this.ngUnsubscribe$)).subscribe(response => {
      const tall = response.totalPropio + response.totalDeposito;
      this._settlementService.getSumSettlementByHarvest(this.currentHarvest).subscribe(response2 => {
        console.log(response, response2);
        this.summative.propio = response2.totalqq;
        this.summative.deposito = parseFloat(Number(Number(tall) - Number(response2.totalqq)).toFixed(2));

      }, err => console.error('Error al obtener la sumatoria de liquidaciones: ', err));
    }, err => console.error('Error al obtener la sumatoria: ', err));
  }

  editWeightNote = (wn: string): void => {
    console.log('to edit', wn);
    this._router.navigateByUrl(`notas-de-peso/editar-nota/${wn}`);
/*     this._inventoryService.editAccess(wn).subscribe((res) => {
      console.log('edit access', res);
      if (res.settlement) {
        const dialogRef = this.dialog.open(DeleteModalComponent, {
          panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
          width: '400px',
          data: {
            header: 'No puede editar la nota de peso',
            title: '',
            text: `La nota de peso ya fué liquidada`
          }
        });
      } else if (res.dried) {
        const dialogRef = this.dialog.open(DeleteModalComponent, {
          panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
          width: '400px',
          data: {
            header: 'No puede editar la nota de peso',
            title: '',
            text: `La nota de peso fué utilizada en secado`
          }
        });
      } else {
        this._router.navigateByUrl(`notas-de-peso/editar-nota/${wn}`);
      }
    }, (err: HttpErrorResponse) => console.error('Error al verificar accesso a editar wn', err)); */
  }

  exportAsXLSX(): void {
    const filters = _.cloneDeep(this.filterDataValues);
    filters.skip = 0;
    filters.limit = 25000;
    this._inventoryService.getDataWithFilters(filters).subscribe(res => {
      const inventories = res as Inventory[];
      let convertToExcel: any = inventories;
      const pipe = new DatePipe('es-HN');
      convertToExcel.forEach((element, i) => {
          const newDate = pipe.transform(element.date, 'shortDate');
          const certs = element.certs === null ? [] : element.certs;
          const varieties = !element.origin ? [] : (element.origin.varieties ? element.origin.varieties : []);
          const house = element.certHouse === null ? '' : element.certHouse;
          const driver = element.driver === null ? '' : element.driver;
          // const variety = element.variety === null ? '' : element.variety;
          const variety = (element.varieties && element.varieties.length > 0) ? '' : element.variety;
          const flete = element.flete === null ? '' : element.flete;
          const microlot = element.flete === null ? false : element.microlot;
          const process = element.process === null ? '' : element.process;
          let bags = 0;
          let troco = 0;
          element.inventoryDetails.forEach((item) => {
            console.log(item.sacos, bags);
            bags += item.sacos;
          });
          element.inventoryDetails.forEach((item) => {
            troco += item.tareTroco;
          });

          console.log(bags);
          const rows = {
            Código: element.cod,
            Fecha: newDate,
            Casa_Certificadora: house,
            Total_Neto_qq: element.total_net_qq,
            Total_Neto_lb: element.total_net_lb,
            Total_Bruto: element.gross_total_lb,
            '% Humedad': element.inventoryDetails.length > 0 ?
              _.sumBy(element.inventoryDetails, 'desc_humedad') /  element.inventoryDetails.length : 'N/A',
            Entrada: element.entry,
            Estado: element.state,
            Costo_por_Beneficiado: element.cost_per_beneficiary,
            Productor: !element.coffeefarmer ? '' : element.coffeefarmer.name,
            Bodega: !element.warehouse ? '' : element.warehouse.name,
            Finca: !element.origin ? '' : element.origin.name,
            Recepción: element.reception,
            Certificaciones: certs.toString(),
            Variedades: varieties.toString(),
            Tara: element.tare,
            Sacos: bags,
            Troco: troco,
            Microlote: element.microlot,
            Proceso: process,
            Variedad: variety,
            Cosecha: element.harvest,
            Transportista: driver,
            Flete: flete,
            Observaciones: element.observations,
          };

          console.log(rows);

          convertToExcel = Object.assign([], convertToExcel, { [i]: rows });
      });
      console.log(convertToExcel);
        this.excelService.exportAsExcelFile(convertToExcel, 'Notas_de_Peso');
      }, error => console.error('Error al obtener nota de peso para exportar: ', error)
    );
  }

  deleteInventory(id) {
    this.alertMessage = '';
    this._advancePaymentService
      .getAdvancePerWeightNotes(id)
      .subscribe(response => {

        this._driedService.getDriedPerWeightNote(id).subscribe(respons => {
          this._settlementService
            .getSettlementPerWeightNotes(id)
            .subscribe(res => {
              this._transferService
                .getTransferPerWeightNotes(id)
                .subscribe(transfer => {
                  if (
                    response.length === 0 &&
                    respons.length === 0 &&
                    res.length === 0 &&
                    transfer.length === 0
                  ) {
                    this.delete(id);
                  } else {
                    this.alertMessage = 'la nota de peso no se puede borrar';
                  }
                });
            });
        });
      });
  }

  delete(id) {
    this.alertMessage = 'Nota de peso eliminada';
    this._inventoryService.deleteInventory(id).subscribe(
      response => {
        if (!response) {
          alert('Error en el servidor');
        } else {
          this.page = 0;
          this.filterData(this.filterDataValues);
        }
      }, error => console.error('Error al eliminar nota de peso: ', error)
    );
  }

  clearFilter(): void {
    this.filterDataValues = {};
    this.filterDataValues['harvest'] = this.filterDataValues['harvest'] === 'Todas' ? 'Todas' : this.currentHarvest;
    this.filterData(this.filterDataValues);
  }

  saveFile(file): void {
    this.selectedFile = file;
  }

  openMultiplePrint = (): void => {

  }

  // * Actualizar las fechas de las notas de peso.
  importData(list: any[]): void {
    // tslint:disable-next-line:no-shadowed-variable
    const data: any = {
      currentList: list,
      start: this.page * 100,
      end: (this.page) * 100 + 100
    };
    if (this.selectedFile !== null) {
      this._importDataService.importWeightNotesToUpdate(this.selectedFile, data);
    }
  }

  importDataWarehouses(list: any[]): void {
    // tslint:disable-next-line:no-shadowed-variable
    const data: any = {
      currentList: list,
      start: this.page * 100,
      end: (this.page) * 100 + 100
    };
    if (this.selectedFile !== null) {
      this._importDataService.importWarehouseToUpdate(this.selectedFile, data);
    }
  }

  /* * Sidebar */
  openSidebar(name: string, data: any | Inventory = null): void {
    this._sidebarManager.open(name, data);
    this._sidebarManager.afterClosed(name).subscribe((res) => {
      if (res) this.filterData(this.filterDataValues);
    });
  }

    // ? -----------------------------
    goUp(): void {
      // window.document.getElementById('generalContent').scrollTo(0, 0);
    }

    // ? --------------
    /* dataSub () {
      this.datacod.forEach((element, index) => {
        this.dates.forEach(item => {
          if (element.cod === item.cod) {
            this.invento.date = item.date;
            this._inventoryService.editInventory(element._id, this.invento).subscribe(
              response => {
                console.log('creado con exito ' + index);
              },
              error => {
                console.log(error);
              }
            );
          }
        });
      });
    } */

    /* public func = function(event) {
      const scrollTop =  (event.target as HTMLTextAreaElement).scrollTop;
      const btn = document.getElementById('btnUp');
      if (scrollTop > 200) {
        btn.style.display = 'block';
      } else {
        btn.style.display = 'none';
      }
    }; */
}

interface SidebarEmit {
  app: string;
  reload: boolean;
}
