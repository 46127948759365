import { ProductionService } from './services/production.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { routing, appRoutingProviders } from './app.routing';
import { TextMaskModule } from 'angular2-text-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule, DatePipe } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AvatarModule } from 'ngx-avatar';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSelectSearchModule } from './pages/mat-select-search/mat-select-search.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './shared/right-sidebar/rightsidebar.component';
import { AppComponent } from './app.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { DeleteModalComponent } from './pages/delete-modal/delete-modal.component';
import { SearchByCodePipe } from './pages/filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ReportsComponent } from './pages/reports/reports.component';
import { StateReportComponent } from './pages/state-report/state-report.component';
import { DepositReportComponent } from './pages/deposit-report/deposit-report.component';
import { PurchaseDateComponent } from './pages/purchase-date/purchase-date.component';
import { PurchaseCoffeefarmerComponent } from './pages/purchase-coffeefarmer/purchase-coffeefarmer.component';
import { StarterComponent } from './pages/starter/starter.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { UserComponent } from './user/user.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AsisstanceService } from './services/asisstance.service';
import { JwtModule } from '@auth0/angular-jwt';
import { NgApexchartsModule } from 'ng-apexcharts';


import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-HN';

/*----- charts------*/
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EditDateDriedComponent } from './pages/dry/edit-date-dried/edit-date-dried.component';
import { LoaderComponent } from './pages/loader/loader.component';
import { CentercostAddComponent } from './pages/centercost-add/centercost-add.component';
import { CentercostEditComponent } from './pages/centercost-edit/centercost-edit.component';
import { AccountingModule } from './pages/accounting/accounting.module';
import { SettingsComponent } from './pages/settings/settings.component';
import { BankComponent } from './pages/bank/bank.component';
import { BankAddComponent } from './pages/bank-add/bank-add.component';
import { BankEditComponent } from './pages/bank-edit/bank-edit.component';
import { ProveedoresComponent } from './pages/proveedores/proveedores.component';
import { AddproveedoresComponent } from './pages/addproveedores/addproveedores.component';
import { ProveedoresAddComponent } from './pages/proveedores-add/proveedores-add.component';
import { ProveedoresEditComponent } from './pages/proveedores-edit/proveedores-edit.component';
import { UploadimagenComponent } from './pages/uploadimagen/uploadimagen.component';
import { Fd3Component } from './pages/fd3/fd3.component';
import { Fd3AddComponent } from './pages/fd3-add/fd3-add.component';
import { Fd3EditComponent } from './pages/fd3-edit/fd3-edit.component';
import { Fd32Component } from './pages/fd32/fd32.component';
import { Fd32AddComponent } from './pages/fd32-add/fd32-add.component';
import { Fd32EditComponent } from './pages/fd32-edit/fd32-edit.component';
import { Fd33EditComponent } from './pages/fd33-edit/fd33-edit.component';
import { Fd33AddComponent } from './pages/fd33-add/fd33-add.component';
import { Fd33Component } from './pages/fd33/fd33.component';
import { Fd34Component } from './pages/fd34/fd34.component';
import { Fd34AddComponent } from './pages/fd34-add/fd34-add.component';
import { Fd34EditComponent } from './pages/fd34-edit/fd34-edit.component';
import { LiquidationPdfComponent } from './pages/liquidation-pdf/liquidation-pdf.component';

import { CdkTableModule } from '@angular/cdk/table';

import { MatSortModule } from '@angular/material/sort';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { PinUpComponent } from './pages/pin-up/pin-up.component';
import { LotService } from './services/lot.service';
import { SettlementService } from './services/settlement.service';
import { ExpenseService } from './services/expense.service';
import { SelectCategoryComponent } from './pages/accounting/seat-edit/select-category/select-category.component';
import { DriedUpdateComponent } from './pages/dry/dried-update/dried-update.component';
import { PaymentService } from './services/payment.service';
import { AdvancePaymentService } from './services/advancePayment.service';
import { PrintService } from './services/print.service';
import { DriedsFilterComponent } from './pages/dry/drieds/drieds-filter/drieds-filter.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { ImportDataService } from './services/import-data.service';
import { TestingService } from './services/testing.service';
import { InventoryService } from './services/inventory.service';
import { FixService } from './services/fix.service';
import { ProductorService } from './services/productor.service';
import { SeatService } from './services/seat.service';
import { OriginService } from './services/origin.service';
import { CertificationService } from './services/certification.service';
import { WarehouseService } from './services/warehouse.service';
import { DriedService } from './services/dried.service';
import { SaleService } from './services/sale.service';
import { SocketIoService } from './services/io.service';
import { FarmerService } from './services/farmer.service';
import { ReferralService } from './services/referral.service';
import { ExcelService } from './services/excel.service';
import { ChekkuService } from './services/chekku.service';
import { ReportService } from './services/reports.service';
import { TransferService } from './services/transfer.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomerService } from './services/customer.service';
import { BrokerService } from './services/broker.service';
import { ExporterService } from './services/exporter.service';
import { IhcafeVoucherService } from './services/ihcafe-voucher.service';
import { AdministrationModule } from './pages/administration/administration.module';
import { WeightNoteModule } from './pages/weight-note/weight-note.module';
import { CenterCostService } from './services/center-cost.service';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { DryModule } from './pages/dry/dry.module';
import { ExportsModule } from './pages/exports/exports.module';
import { ProductionModule } from './pages/production/production.module';
import { NgxMaskModule } from 'ngx-mask';
import { ImporterComponent } from './pages/importer/importer.component';
import { QAModule } from './pages/QA/qa.module';
import { CheckInsComponent } from './pages/chekku/check-ins/check-ins.component';
import { SocketIoModule } from 'ngx-socket-io';
import { AsisstanceAddComponent } from './pages/asissts/asisstance-add/asisstance-add.component';
import { AsisstanceEditComponent } from './pages/asissts/asisstance-edit/asisstance-edit.component';
import { AsisstsComponent } from './pages/asissts/asissts/asissts.component';
import { PaginationComponent } from './pages/pagination/pagination.component';
import { SamplesModule } from './pages/QA/samples/samples.module';
import { ProductionsModule } from './pages/production/productions/productions.module';
import { ListComponent } from './pages/chekku/geo-list/list/list.component';
import { CreateComponent } from './pages/chekku/geo-list/create/create.component';
// import { AsisstsModule } from './pages/asissts/asissts.module';

export function tokenGetter() {
  return localStorage.getItem('token');
}
registerLocaleData(localeEs, 'es-HN');
const routes: Routes = [
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    RightSidebarComponent,
    UserEditComponent,
    DeleteModalComponent,
    SearchByCodePipe,
    ReportsComponent,
    StateReportComponent,
    DepositReportComponent,
    PurchaseDateComponent,
    PurchaseCoffeefarmerComponent,
    StarterComponent,
    SignInComponent,
    UserComponent,
    LoaderComponent,
    CentercostAddComponent,
    CentercostEditComponent,
    SettingsComponent,
    BankComponent,
    BankAddComponent,
    BankEditComponent,
    ProveedoresComponent,
    AddproveedoresComponent,
    ProveedoresAddComponent,
    ProveedoresEditComponent,
    UploadimagenComponent,
    Fd3Component,
    Fd3AddComponent,
    Fd3EditComponent,
    Fd32Component,
    Fd32AddComponent,
    Fd32EditComponent,
    Fd33EditComponent,
    Fd33AddComponent,
    Fd33Component,
    Fd34Component,
    Fd34AddComponent,
    Fd34EditComponent,
    PinUpComponent,
    LiquidationPdfComponent,
    UserProfileComponent,
    ImporterComponent,
    CheckInsComponent,
    AsisstanceAddComponent,
    AsisstanceEditComponent,
    AsisstsComponent,
    ListComponent,
    CreateComponent,
    // PaginationComponent
  ],

  entryComponents: [
    DeleteModalComponent,
    BankAddComponent,
    Fd3AddComponent,
    Fd32AddComponent,
    UploadimagenComponent,
    Fd3EditComponent,
    Fd32EditComponent,
    Fd33EditComponent,
    Fd33AddComponent,
    Fd34AddComponent,
    Fd34EditComponent,
    PinUpComponent,
    EditDateDriedComponent,
    SelectCategoryComponent,
    DriedUpdateComponent,
    DriedsFilterComponent,
    SelectCategoryComponent,
    CreateComponent,
  ],

  imports: [
    BrowserModule,
    CommonModule,
    HttpClientJsonpModule,
    NgbModule.forRoot(),
    SocketIoModule,
    AvatarModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    // tslint:disable-next-line: deprecation
    HttpModule,
    HttpClientModule,
    MatSelectSearchModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['gestopps.com'],
        blacklistedRoutes: ['gestopps.com/admin/'],
      },
    }),
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    TextMaskModule,
    routing,
    MatAutocompleteModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    PdfViewerModule,
    NgxChartsModule,
    MaterialModule,
    AccountingModule,
    ScrollingModule,
    CdkTableModule,
    MatSortModule,
    TableVirtualScrollModule,
    NgApexchartsModule,
    InfiniteScrollModule,
    WeightNoteModule,
    AdministrationModule,
    QAModule,
    DryModule,
    // AsisstsModule,
    ExportsModule,
    ProductionModule,
    // ProductionsModule,
    SamplesModule,
    NgxMaskModule.forRoot(),
    // QAModule,
    // ToastrModule.forRoot(), // ToastrModule added
  ],
  exports: [
    MatDatepickerModule,
    // PaginationComponent,
    // StepperComponent
  ],
  providers: [
    appRoutingProviders,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}
    },
    DatePipe,
    UserService,
    AuthGuard,
    RoleGuard,
    AuthService,
    LotService,
    AsisstanceService,
    SettlementService,
    ExpenseService,
    PaymentService,
    AdvancePaymentService,
    PrintService,
    ImportDataService,
    FixService,
    TestingService,
    InventoryService,
    SocketIoService,
    FarmerService,
    IhcafeVoucherService,
    AdvancePaymentService,
    UserService,
    SettlementService,
    PaymentService,
    ProductorService,
    SeatService,
    OriginService,
    CertificationService,
    WarehouseService,
    DriedService,
    SaleService,
    TransferService,
    ReportService,
    ExcelService,
    CustomerService,
    BrokerService,
    ExporterService,
    CenterCostService,
    ProductionService,
    ReferralService,
    ChekkuService,
    // { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
